import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";

const GENERATE_SUMMARY = gql`
  mutation GenerateSummary($itemId: String!) {
    generateSummary(itemId: $itemId) {
      result
      newSummary
    }
  }
`;

export default function GenerateSummaryButton({ item, onGenerateSuccess }) {
  const [generateSummary, { loading, error }] = useMutation(GENERATE_SUMMARY);
  const [isDisabled, setIsDisabled] = useState(false); // New state variable

  const handleGenerate = async () => {
    if (isDisabled) return; // Prevents function execution if button is already disabled

    try {
      const { data } = await generateSummary({
        variables: { itemId: item.id },
      });
      if (data.generateSummary.result === "success") {
        alert("successfully generated summary");
        setIsDisabled(true); // Disable button after successful request
        if (onGenerateSuccess) {
          onGenerateSuccess(item.id);
        }
      } else {
        alert(
          data.generateSummary.message ||
            "An error occurred while generating summary."
        );
      }
    } catch (error) {
      console.error("Failed to generate summary:", error);
      alert("Failed to generate summary: " + error);
    }
  };

  return (
    <>
      <button
        className="control-panel-button-rectangular"
        onClick={handleGenerate}
        disabled={loading || isDisabled} // Button is disabled when loading or after successful request
      >
        {isDisabled ? "success!" : loading ? "hang on" : "generate summary"}
      </button>
      {error && <p>Error in generating summary.</p>}
    </>
  );
}
