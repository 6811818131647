import React from "react";
import Users from "./Users";
import Messages from "./Messages";
import TriggerInformationLoginEmail from "./components/TriggerInformationLoginEmail";
import TriggerSundayMilkEmail from "./components/TriggerSundayMilkEmail";

export default function Admin() {
  return <div className="m-5">
    <TriggerInformationLoginEmail />
    <TriggerSundayMilkEmail/>
    <Users />
    <Messages />
  </div>;
}
