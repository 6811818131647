import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";

const TRIGGER_EMAIL = gql`
  mutation TriggerSundayMilkEmail  {
    triggerSundayMilkEmail {
      result
    }
  }
`;

export default function TriggerSundayMilkEmail() {
  const [triggerEmail, { loading, error }] = useMutation(TRIGGER_EMAIL);
  const [isDisabled, setIsDisabled] = useState(false); // New state variable

  const handleTriggerEmail = async () => {
    if (isDisabled) return; // Prevents function execution if button is already disabled

    try {
      const { data } = await triggerEmail();
      if (data.TriggerSundayMilkEmail.result === "success") {
        alert("successfully triggered email");
        setIsDisabled(true); // Disable button after successful request
      } else {
        alert(
          data.generateSummary.message ||
            "An error occurred while triggering sunday milk email."
        );
      }
    } catch (error) {
      console.error("Failed to do the thing:", error);
      alert("Failed to do the thing: " + error);
    }
  };

  return (
    <>
      <button
        className="control-panel-button-rectangular"
        onClick={handleTriggerEmail}
        disabled={loading || isDisabled} // Button is disabled when loading or after successful request
      >
        {isDisabled ? "success!" : loading ? "hang on.." : "trigger sunday milk email"}
      </button>
      {error && <p>Error in triggering email.</p>}
    </>
  );
}
