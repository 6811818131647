import React, { useEffect } from "react";

function StudyFeedTypeSwitch({ setStudyFeedType, currentStudyFeedType }) {
  const isSelected = (studyFeedType) =>
    studyFeedType === currentStudyFeedType ? "selected" : "";
  return (
    <div style={{ display: "flex", gap: "10px", padding: "10px" }}>
      <button
        className={isSelected("algo")}
        onClick={() => {
          setStudyFeedType("algo");
        }}
      >
        algo
      </button>
      <button
        className={isSelected("shuffle")}
        onClick={() => {
          setStudyFeedType("shuffle");
        }}
      >
        shuffle
      </button>
    </div>
  );
}

export default StudyFeedTypeSwitch;
