import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function MagicLogin() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    if (token) {
      localStorage.setItem("token", token);
      navigate("/"); // Redirect to the homepage or dashboard after storing the token
    } else {
      alert("Error logging in! Token is missing. You aint logged in!");
      navigate("/login", { replace: true }); // Redirect back to login or another appropriate route
    }
  }, [location, navigate]);
  return null;
}
