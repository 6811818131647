import React, { useEffect, useState } from "react";
import { useMutation, gql } from "@apollo/client";

const TOGGLE_USER_FLAG = gql`
  mutation ToggleUserFlag($fieldName: String!, $newValue: Boolean!) {
    toggleUserFlag(fieldName: $fieldName, newValue: $newValue) {
      success
      message
    }
  }
`;

export default function UserFlagToggle({
  fieldName,
  initialValue,
  displayTextForTrue,
  displayTextForFalse,
  onToggle,
}) {
  const [toggleUserFlagMutation] = useMutation(TOGGLE_USER_FLAG);
  const [currentValue, setCurrentValue] = useState(initialValue);
  useEffect(() => {
    setCurrentValue(initialValue);
  }, [initialValue]);

  const handleToggleClick = async () => {
    const newValue = !currentValue;

    try {
      const response = await toggleUserFlagMutation({
        variables: { fieldName: fieldName, newValue: newValue },
      });
      if (response.data.toggleUserFlag.success) {
        setCurrentValue(newValue);
        if (typeof onToggle === "function") {
          onToggle();
        }
      } else {
        alert(
          "Mutation was not successful: " + response.data.toggleUserFlag.message
        );
      }
    } catch (error) {
      console.error("Error toggling user flag:", error);
    }
  };

  return (
    <div onClick={handleToggleClick}>
      {currentValue ? displayTextForTrue : displayTextForFalse}
    </div>
  );
}
