import React, { useState, useCallback, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_SHIT } from "../queries";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  OverlayView,
  DirectionsRenderer,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 47.0502,
  lng: 8.3093,
};

const mapOptions = {
  zoomControl: true,
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  styles: [
    {
      featureType: "poi",
      stylers: [{ visibility: "off" }],
    },
  ],
};

const testShit = [
  {
    id: 4,
    name: "test name",
  },
];

function GoogleMapsView({
  onLocationChange,
  waypoints,
  directions,
  onItemMarkerClick,
  onTour,
}) {
  const [map, setMap] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [waypointIcon, setWaypointIcon] = useState(null);
  const [shit, setShit] = useState();
  const [formattedUserLocation, setFormattedUserLocation] = useState(null);
  const [userHasMovedMap, setUserHasMovedMap] = useState(false);
  // const loaderOptions = {
  //   id: "google-map-script",
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  // };
  const [lastActionLocation, setLastActionLocation] = useState(null);
  const {
    loading: getShitLoading,
    error: getShitError,
    data: getShitData,
  } = useQuery(GET_SHIT);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const hexColors = {
    IndigoDye: "#0077B6",
    TurkeyRed: "#FF3333",
    SafetyOrange: "#FF8000",
    PeachYellow: "#FFEEB4",
    Verdigris: "#3CDBC0",
    Timberwolf: "#DEDBD8",
    MidnightGreen: "#0F4C5C",
    LapisLazuli: "#2660A4",
    Nyanza: "#F4FFF2",
    BrightPink: "#FF90CE",
  };

  const colors = [
    ["IndigoDye", 204, 100, 47],
    ["TurkeyRed", 0, 100, 64],
    ["SafetyOrange", 28, 100, 100],
    ["PeachYellow", 42, 41, 94],
    ["Verdigris", 182, 100, 71],
    ["timberwolf", 51, 3, 87],
    ["midnight green", 195, 82, 37],
    ["lapis lazuli", 211, 52, 56],
    ["nyanza", 114, 11, 99],
    ["bright pink", 355, 63, 96],
    ["wine", 346, 64, 42],
    ["timberwolf", 51, 3, 87],
    ["pantone red", 355, 75, 90],
    ["Honeydew", 105, 15, 98],
    ["non photo blue", 182, 24, 86],
    ["cerulean", 203, 56, 62],
    ["berkely blue", 215, 67, 34],
    ["timberwolf", 51, 3, 87],
    ["oxford blue", 215, 87, 15],
    ["Honeydew", 105, 15, 98],
    ["non photo blue", 182, 24, 86],
    ["cerulean", 203, 56, 62],
    ["berkely blue", 215, 67, 34],
    ["photo blue", 182, 56, 86],
  ];
  // function hsbToHex(h, s, b) {
  //   s /= 100;
  //   b /= 100;

  //   let k = (n) => (n + h / 60) % 6;
  //   let f = (n) => b - b * s * Math.max(Math.min(k(n), 4 - k(n), 1), 0);

  //   let r = Math.round(f(5) * 255);
  //   let g = Math.round(f(3) * 255);
  //   let bColor = Math.round(f(1) * 255);

  //   return (
  //     "#" +
  //     ((1 << 24) + (r << 16) + (g << 8) + bColor)
  //       .toString(16)
  //       .slice(1)
  //       .toUpperCase()
  //   );
  // }

  function getRandomColorHex() {
    const randomIndex = Math.floor(Math.random() * colors.length);
    const [name, h, s, b] = colors[randomIndex];
    const hexColor = hsbToHex(h, s, b);
    return hexColor;
  }

  useEffect(() => {
    if (isLoaded && window.google) {
      setWaypointIcon({
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: "#ffff00",
        fillOpacity: 1,
        strokeColor: "#ffffff",
        strokeWeight: 2,
        scale: 8,
      });
    }
  }, [isLoaded]);

  useEffect(() => {
    if (
      isLoaded &&
      map &&
      directions &&
      directions.routes &&
      directions.routes[0] &&
      directions.routes[0].legs &&
      directions.routes[0].legs[0]
    ) {
      // Center the map on the start of the route
      const startLocation = directions.routes[0].legs[0].start_location;
      map.setCenter(startLocation);

      const fixedZoomLevel = 18;
      map.setZoom(fixedZoomLevel);
    }
  }, [isLoaded, directions, map]);

  useEffect(() => {
    if (getShitLoading) return;

    if (getShitError) {
      console.error("Error checking getting shit:", getShitError);
      return;
    }

    if (getShitData && getShitData.getShit) {
      const response = getShitData.getShit;

      if (response.items) {
        setShit(response.items);
      } else {
        console.warn("no shit");
      }
    }
  }, [getShitLoading, getShitError, getShitData]);

  useEffect(() => {
    if (directions) {
      console.log("directions.routes[0].bounds");
      console.log(directions.routes[0].bounds);
    }
  }, [directions]);

  useEffect(() => {
    let watchId;
    if (isLoaded) {
      watchId = watchUserLocation();
    }
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, [isLoaded, onLocationChange]);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onMapClick = useCallback((e) => {
    setSelectedLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  }, []);
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c * 1000; // Distance in meters
    return distance;
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  const watchUserLocation = useCallback(() => {
    if (navigator.geolocation) {
      const watchId = navigator.geolocation.watchPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setFormattedUserLocation(
            `${pos.lat.toFixed(6)}, ${pos.lng.toFixed(6)}`
          );
          setUserLocation(pos);

          if (!userHasMovedMap && map) {
            map.panTo(pos);
          }

          if (lastActionLocation) {
            const distance = calculateDistance(
              lastActionLocation.lat,
              lastActionLocation.lng,
              pos.lat,
              pos.lng
            );
            if (distance >= 2) {
              if (onLocationChange) {
                onLocationChange(pos);
              }

              setLastActionLocation(pos);
            }
          } else {
            setLastActionLocation(pos);
          }
        },
        (error) => {
          console.error("Geolocation error:", error.code, error.message);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
      return watchId;
    } else {
      alert("Error: Your browser doesn't support geolocation.");
    }
  }, [onLocationChange, lastActionLocation, userHasMovedMap, map]);
  const centerMapOnUser = () => {
    if (userLocation && map) {
      map.panTo(userLocation);
      setUserHasMovedMap(false);
    }
  };

  const getWaypointColor = (waypoint) => {
    return waypoint.isBeforeOrAtCurrentStep // before points will actually just disappear for now, so the bright pink should never be returned
      ? hexColors["BrightPink"]
      : waypoint.audioUrl == null
      ? hexColors["IndigoDye"]
      : hexColors["PeachYellow"];
  };
  function hsbToHex(h, s, b) {
    s /= 100;
    b /= 100;

    let k = (n) => (n + h / 60) % 6;
    let f = (n) => b - b * s * Math.max(Math.min(k(n), 4 - k(n), 1), 0);

    let r = Math.round(f(5) * 255);
    let g = Math.round(f(3) * 255);
    let bColor = Math.round(f(1) * 255);

    return (
      "#" +
      ((1 << 24) + (r << 16) + (g << 8) + bColor)
        .toString(16)
        .slice(1)
        .toUpperCase()
    );
  }

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }
  return isLoaded ? (
    <div style={{ height: "100%" }}>
      {/* {formattedUserLocation && (
        <div
          style={{
            position: "absolute",
            bottom: "20px",
            left: "20px",
            background: "white",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
            zIndex: 1000,
          }}
        >
          Current Location: {formattedUserLocation}
        </div>
      )} */}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={userHasMovedMap ? undefined : userLocation || center}
        zoom={17}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={onMapClick}
        onDragStart={() => setUserHasMovedMap(true)}
        options={mapOptions}
      >
        {userLocation && (
          <Marker
            position={userLocation}
            icon={{
              path: "M 0, 0 m -6, 0 a 6,6 0 1,0 12,0 a 6,6 0 1,0 -12,0",
              fillColor: "#000000",
              fillOpacity: 1,
              strokeWeight: 0,
              scale: 1,
            }}
          />
        )}
        {selectedLocation && <Marker position={selectedLocation} />}

        {waypoints &&
          waypointIcon &&
          waypoints.map((waypoint) => (
            <React.Fragment key={waypoint.id}>
              {!waypoint.isBeforeOrAtCurrentStep && (
                <Marker
                  position={{
                    lat: parseFloat(waypoint.lat),
                    lng: parseFloat(waypoint.lon),
                  }}
                  icon={{
                    path: window.google.maps.SymbolPath.CIRCLE,
                    fillColor: getWaypointColor(waypoint),
                    fillOpacity: 1,
                    strokeWeight: 1,
                    strokeColor: "#FFFFFF", // Changed to white
                    scale: 7,
                  }}
                  title={waypoint.name}
                />
              )}
              {/* labels for waypoints */}
              <OverlayView
                position={{
                  lat: parseFloat(waypoint.lat),
                  lng: parseFloat(waypoint.lon),
                }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <div
                  style={{
                    background: "white",
                    padding: "5px",
                    borderRadius: "3px",
                    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                    position: "absolute",
                    transform: "translate(10px, -50%)",
                    whiteSpace: "nowrap",
                  }}
                >
                  {waypoint.name}
                </div>
              </OverlayView>
            </React.Fragment>
          ))}
        {shit &&
          !onTour &&
          shit.map((item) => (
            <React.Fragment key={item.id}>
              {
                <Marker
                  position={{
                    lat: parseFloat(item.lat),
                    lng: parseFloat(item.lon),
                  }}
                  icon={{
                    path: window.google.maps.SymbolPath.CIRCLE,
                    fillColor: "#ff871a",
                    fillOpacity: 1,
                    strokeWeight: 1,
                    strokeColor: "#FFFFFF",
                    scale: 7,
                  }}
                  title={item.name}
                  onClick={() => onItemMarkerClick(item)}
                />
              }
              {/* <OverlayView
                position={{
                  lat: parseFloat(waypoint.lat),
                  lng: parseFloat(waypoint.lon),
                }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <div
                  style={{
                    background: "white",
                    padding: "5px",
                    borderRadius: "3px",
                    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                    position: "absolute",
                    transform: "translate(10px, -50%)",
                    whiteSpace: "nowrap",
                  }}
                >
                  {waypoint.name}
                </div>
              </OverlayView> */}
            </React.Fragment>
          ))}
        {directions && (
          <DirectionsRenderer
            directions={directions}
            options={{
              preserveViewport: true,
              polylineOptions: {
                strokeColor: hexColors["LapisLazuli"],
                strokeWeight: 5,
              },
              // suppressMarkers: true,
              suppressMarkers: false,
              markerOptions: {
                icon: {
                  path: window.google.maps.SymbolPath.CIRCLE,
                  scale: 7,
                  fillColor: hexColors["Verdigris"],
                  // fillOpacity: 1,
                  fillOpacity: 0,
                  // strokeWeight: 2,
                  strokeWeight: 0,
                  strokeColor: "#ffffff",
                },
              },
            }}
          />
        )}
      </GoogleMap>
      <button
        onClick={centerMapOnUser}
        style={{
          position: "absolute",
          right: "1px",
          bottom: "110px", // Adjust this value as needed
          backgroundColor: "#fff",
          border: "none",
          borderRadius: "2px",
          boxShadow: "0 1px 4px rgba(0,0,0,0.3)",
          cursor: "pointer",
          margin: "10px",
          padding: "0px",
          width: "40px",
          height: "40px",
          textAlign: "center",
          zIndex: 1000,
        }}
        aria-label="Center map on your location"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="20px"
            height="20px"
            fill="#666"
          >
            <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3A8.994 8.994 0 0013 3.06V1h-2v2.06A8.994 8.994 0 003.06 11H1v2h2.06A8.994 8.994 0 0011 20.94V23h2v-2.06A8.994 8.994 0 0020.94 13H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
          </svg>
        </div>
      </button>
      {selectedLocation && (
        <div>
          Selected Location: {selectedLocation.lat.toFixed(4)},{" "}
          {selectedLocation.lng.toFixed(4)}
        </div>
      )}
    </div>
  ) : (
    <div>Loading...</div>
  );
}

export default GoogleMapsView;
