import React from "react";
import { useMutation, gql } from "@apollo/client";

const DELETE_ITEM = gql`
  mutation DeleteItem($id: String!) {
    deleteItem(id: $id) {
      result
    }
  }
`;

export default function DeleteItem({
  displayText,
  transparentBg,
  item,
  onDelete,
}) {
  const [deleteItem, { loading, error }] = useMutation(DELETE_ITEM);

  const handleDelete = async () => {
    try {
      var confirm = window.confirm("Sure you want to delete this?");
      if (!confirm) {
        return;
      }

      const { data } = await deleteItem({ variables: { id: item.id } });
      if (data.deleteItem.result) {
        if (onDelete) {
          onDelete(item.id);
        }
      } else {
        alert(
          data.deleteItem.message ||
            "An error occurred while deleting the item."
        );
      }
    } catch (error) {
      console.error("Failed to delete item", error);
    }
  };

  return (
    <button
      className="control-panel-button-rectangular"
      style={{
        backgroundColor: transparentBg ? "transparent" : "",
      }}
      onClick={handleDelete}
      disabled={loading}
    >
      {loading ? ".." : displayText}
    </button>
  );
}
