import React, { useCallback, useState, useRef, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useMutation, gql } from "@apollo/client";
import rehypeRaw from "rehype-raw";
import { CAPTURE } from "../mutations";

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export default function ItemText({ item, onHighlightCreation }) {
  const [selectedText, setSelectedText] = useState();
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const popupRef = useRef(null);
  const [captureMutation, { data, loading, error }] = useMutation(CAPTURE);
  const [itemText, setItemText] = useState(item.text);

  const replaceTextWithHighlightHtml = (text, highlightText) => {
    const regex = new RegExp(escapeRegExp(highlightText), "g");
    return text.replace(
      regex,
      `<span className="highlighted">${highlightText}</span>`
    );
  };

  useEffect(() => {
    if (!item.text) return;
    if (item.childItems) {
      const highlight_items = item.childItems.filter(
        (child) => child.type === "HGLT"
      );

      let it = item.text; // it is for itemText but I'm avoiding a name collision
      highlight_items.forEach((highlightItem) => {
        it = replaceTextWithHighlightHtml(it, highlightItem.text);
      });

      setItemText(it);
    }
  }, [item]);

  const handleSelection = useCallback(() => {
    setTimeout(() => {
      const selectedText = window.getSelection().toString();

      if (selectedText) {
        const range = window.getSelection().getRangeAt(0);
        const rect = range.getBoundingClientRect();

        setSelectedText(selectedText);
        setPosition({
          left: rect.left + window.scrollX,
          top: rect.bottom + window.scrollY,
        });
        setIsCreateModalVisible(true);
      }
    }, 100); // short delay, e.g., 100 milliseconds
  }, []);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsCreateModalVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleActionClick = async (action) => {
    console.log("action: ", action);
    setIsCreateModalVisible(false);
    if (action == "create_highlight") {
      setItemText(replaceTextWithHighlightHtml(itemText, selectedText));
    }
    try {
      captureMutation({
        variables: {
          inputText:
            action == "ask_gpt_concise"
              ? "tell me more about this: " + selectedText
              : selectedText,
          action: action,
          extantItemId: item.id,
        },
      })
        .then((response) => {
          if (action == "create_highlight") {
            // setItemText(
            //   replaceTextWithHighlightHtml(
            //     itemText,
            //     response.data.capture.item.text
            //   )
            // );
          } else {
            alert(
              "Successfully submitted query! We'll send you a text when it's done, and the answer should be nested under this item you're reading."
            );
          }
        })
        .catch((error) => {
          if (action == "create_highlight") {
            alert("Error creating highlight:", error);
          } else {
            alert("Error doing the thing:", error);
          }
        })
        .finally(() => {
          // Code to be executed regardless of success or failure
          // setIsCreateModalVisible(false);
        });
    } catch (error) {
      console.error("Failed to ask gpt:", error);
    }
  };

  return item.text ? (
    <div
      style={{
        whiteSpace: "pre-line",
        whiteSpace: "pre-wrap",
      }}
      onMouseUp={handleSelection}
      onTouchEnd={handleSelection}
    >
      <div
        className="markdown-content"
        style={{
          marginTop: "14px",
        }}
      >
        {/* Since ReactMarkdown v5+, we need to use `components` prop to customize rendering */}
        <ReactMarkdown rehypePlugins={[rehypeRaw]} allowDangerousHtml>
          {itemText}
        </ReactMarkdown>
      </div>
      {isCreateModalVisible && (
        <div
          ref={popupRef}
          style={{
            position: "absolute",
            top: `${position.top}px`,
            left: `${position.left}px`,
            backgroundColor: "white",
            border: "1px solid black",
            padding: "10px",
            zIndex: 1000,
          }}
        >
          <div
            onClick={() => {
              handleActionClick("create_highlight");
            }}
          >
            create highlight
          </div>
          <div
            onClick={() => {
              handleActionClick("ask_gpt_concise");
            }}
          >
            ask gpt
          </div>
          <div
            onClick={() => {
              handleActionClick("add_parent_tag");
            }}
          >
            add parent tag
          </div>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
}
