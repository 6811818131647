export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the Earth in km
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c * 1000; // Distance in meters
};

export const extractWaypoints = (legs, currentLeg, currentStep) => {
  let waypointId = 0;
  var waypoints = legs.flatMap((leg, legIndex) =>
    leg.steps.flatMap((step, stepIndex) => [
      {
        id: waypointId++,
        lat: step.startLocation.lat,
        lon: step.startLocation.lon,
        name: `leg ${legIndex}, step ${stepIndex} start`,
        // audioUrl: step.audioUrl | null, // this was breaking the logic that checks for whether audioUrl is set to determine the waypoint color.
        audioUrl: step.audioUrl,
        isBeforeOrAtCurrentStep:
          currentLeg != null && // everything is "before" the current leg and step if they are null
          currentStep != null &&
          (legIndex < currentLeg ||
            (legIndex === currentLeg && stepIndex <= currentStep)),
      },
    ])
  );
  console.log("waypoints: ", waypoints);
  return waypoints;
};

export const checkAllStepsHaveAudio = (legs) => {
  if (!Array.isArray(legs) || legs.length === 0) {
    return false;
  }

  for (let legIndex = 0; legIndex < legs.length; legIndex++) {
    const leg = legs[legIndex];
    if (!leg.steps || !Array.isArray(leg.steps)) {
      return false;
    }

    for (let stepIndex = 0; stepIndex < leg.steps.length; stepIndex++) {
      const step = leg.steps[stepIndex];
      if (!step.audioUrl) {
        return false;
      }
    }
  }

  return true;
};

export const checkNextStepsHaveAudio = (
  legs,
  currentLeg,
  currentStep,
  numStepsToCheck
) => {
  // let numStepsToCheck = 3;
  let legIndex = currentLeg;
  let stepIndex = currentStep;

  while (numStepsToCheck > 0 && legIndex < legs.length) {
    const leg = legs[legIndex];
    console.log(`leg: ${legIndex}`);
    if (!leg.steps || !Array.isArray(leg.steps)) {
      legIndex++;
      stepIndex = 0;
      continue;
    }

    while (stepIndex < leg.steps.length && numStepsToCheck > 0) {
      const step = leg.steps[stepIndex];
      if (!step.audioUrl) return false;
      numStepsToCheck--;
      stepIndex++;
    }

    if (stepIndex >= leg.steps.length) {
      legIndex++;
      stepIndex = 0;
    }
  }

  return numStepsToCheck === 0;
};

export const printAudioUrls = (legs) => {
  legs.forEach((leg, legIndex) => {
    leg.steps.forEach((step, stepIndex) => {
      console.log(
        `Leg ${legIndex}, Step ${stepIndex}: ${
          step.audioUrl || "No audio URL yet"
        }`
      );
    });
  });
};

export const calculateNewStepDistances = (legs, newLocation) => {
  const newStepDistances = [];
  let closestDistance = Infinity;
  let closestStep = null;

  legs.forEach((leg, legIndex) => {
    leg.steps.forEach((step, stepIndex) => {
      const distance = calculateDistance(
        newLocation.lat,
        newLocation.lon,
        step.startLocation.lat,
        step.startLocation.lon
      );
      newStepDistances.push({
        legIndex,
        stepIndex,
        distance,
      });
      if (distance < closestDistance) {
        closestDistance = distance;
        closestStep = { legIndex, stepIndex, distance };
      }
    });
  });

  // Return an object with both newStepDistances and newClosestStep
  return { newStepDistances, newClosestStep: closestStep };
};

function decodePolyline(encoded) {
  const poly = [];
  let index = 0,
    len = encoded.length;
  let lat = 0,
    lng = 0;

  while (index < len) {
    let b,
      shift = 0,
      result = 0;
    do {
      b = encoded.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    const dlat = result & 1 ? ~(result >> 1) : result >> 1;
    lat += dlat;

    shift = 0;
    result = 0;
    do {
      b = encoded.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    const dlng = result & 1 ? ~(result >> 1) : result >> 1;
    lng += dlng;

    poly.push({ lat: lat * 1e-5, lng: lng * 1e-5 });
  }
  return poly;
}
export const massageDirectionsData = (directionsResponse) => {
  if (
    !directionsResponse ||
    !directionsResponse.routes ||
    directionsResponse.routes.length === 0
  ) {
    console.error("Invalid directions response");
    return null;
  }

  const route = directionsResponse.routes[0];

  // Instead of creating a LatLngBounds object, we'll just pass the bounds directly
  const bounds = {
    southwest: route.bounds.southwest,
    northeast: route.bounds.northeast,
  };

  const massagedLegs = route.legs.map((leg) => ({
    ...leg,
    steps: leg.steps.map((step) => ({
      ...step,
      path: decodePolyline(step.polyline.points),
    })),
  }));

  return {
    routes: [
      {
        ...route,
        bounds: bounds,
        legs: massagedLegs,
      },
    ],
    request: {
      travelMode: "WALKING", // or 'DRIVING', 'BICYCLING', 'TRANSIT' depending on your use case
    },
    status: "OK", // Assuming the status is OK, adjust if necessary
  };
};

export const maybeSetDirections = (response, directions, setDirections) => {
  if (response.directionsJson != null && directions == null) {
    const directionsObjectInner = JSON.parse(response.directionsJson);
    const directionsObject = {
      routes: [directionsObjectInner],
    };

    const massagedDirections = massageDirectionsData(directionsObject);

    if (massagedDirections) {
      setDirections(massagedDirections);
    } else {
      console.error("Failed to massage directions data");
    }
  }
};
