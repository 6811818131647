export function sortChildItems(viewType, item, isPublicView) {
  // return item.childItems;
  let sortedChildItems = [];

  if (viewType === "chronological") {
    sortedChildItems = [...item.childItems].sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  } else if (["top", "arbitrary"].includes(viewType)) {
    const importanceField = isPublicView ? "aggregateImportance" : "importance";
    sortedChildItems = [...item.childItems].sort((a, b) => {
      if (a[importanceField] === null && b[importanceField] === null) return 0;
      if (a[importanceField] === null) return 1;
      if (b[importanceField] === null) return -1;
      return b[importanceField] - a[importanceField];
    });
  } else if (viewType === "shuffle") {
    sortedChildItems = [...item.childItems];
    for (let i = sortedChildItems.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [sortedChildItems[i], sortedChildItems[j]] = [
        sortedChildItems[j],
        sortedChildItems[i],
      ];
    }
  }

  return sortedChildItems;
}

function getTextWidth(text, font) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = font;
  return context.measureText(text).width;
}

export function adjustWidth(selectRef) {
  const selectedOption =
    selectRef.current.options[selectRef.current.selectedIndex];
  const textWidth = getTextWidth(
    selectedOption.text,
    getComputedStyle(selectedOption).fontSize +
      " " +
      getComputedStyle(selectedOption).fontFamily
  );
  selectRef.current.style.width = `${textWidth + 25}px`; // Adding extra width for padding, borders, etc.
}
