import React, { useRef } from "react";
import { gql, useMutation } from "@apollo/client";
import { CREATE_USER } from "../mutations";

function CreateUserComponent() {
  const [mutateFunction, { data, loading, error }] = useMutation(CREATE_USER);
  const usernameRef = useRef();
  const bioRef = useRef();
  const passwordRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const isNpcRef = useRef(); // Ref for the checkbox

  const createUser = () => {
    const usernameValue = usernameRef.current.value;
    const bioValue = bioRef.current.value;
    const passwordValue = passwordRef.current.value;
    const emailValue = emailRef.current.value;
    const phoneValue = phoneRef.current.value;
    const isNpcValue = isNpcRef.current.checked; // Get the checkbox value

    if (!usernameValue || !passwordValue) {
      alert(
        "Please fill out at least username and password before submitting."
      );
      return;
    }

    mutateFunction({
      variables: {
        username: usernameValue,
        bio: bioValue,
        isNpc: isNpcValue, // Use the checkbox value
        password: passwordValue,
        phone: phoneValue,
        email: emailValue,
      },
    })
      .then((response) => {
        console.log("response", response);
        if (response.data.createUser.success === false) {
          alert(response.data.createUser.message);
        }
      })
      .catch((error) => {
        console.error("Mutation Error:", error);
        alert("There was an error creating the user. Please try again.");
      });

    usernameRef.current.value = "";
    bioRef.current.value = "";
    passwordRef.current.value = "";
    emailRef.current.value = "";
    phoneRef.current.value = "";
    isNpcRef.current.checked = false; // Reset the checkbox
  };

  return (
    <div className="form-container">
      <div className="form-group">
        <label className="form-label">
          Username:
          <input ref={usernameRef} type="text" className="form-input" />
        </label>
      </div>
      <div className="form-group">
        <label className="form-label">
          Bio:
          <textarea ref={bioRef} className="form-textarea" />
        </label>
      </div>
      <div className="form-group">
        <label className="form-label">
          Password:
          <input ref={passwordRef} type="password" className="form-input" />
        </label>
      </div>
      <div className="form-group">
        <label className="form-label">
          Email:
          <input ref={emailRef} type="email" className="form-input" />
        </label>
      </div>
      <div className="form-group">
        <label className="form-label">
          Phone:
          <input ref={phoneRef} type="phone" className="form-input" />
        </label>
      </div>
      <div className="form-group checkbox-group">
        <label className="form-checkbox-label">
          <input ref={isNpcRef} type="checkbox" className="form-checkbox" />{" "}
          <div style={{ fontSize: "10px" }}>
            &nbsp; By checking this box, I agree to receive text messages from
            bonsai
          </div>
        </label>
      </div>
      <div className="form-group">
        <button onClick={createUser} className="form-button">
          {/* Create User */}
          Sign Up
        </button>
      </div>
    </div>
  );
}

export default CreateUserComponent;
