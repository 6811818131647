import react, { useState, useEffect, useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useLazyQuery } from "@apollo/client";
import { useSearchParams } from "react-router-dom";
import ItemView from "./components/ItemView";
import StudyFeedTypeSwitch from "./components/StudyFeedTypeSwitch";
import { FEED_ITEMS as FEED } from "./queries";
import Onboarding from "./bamboo-components/Onboarding";
import GlobalContext from "./GlobalContext";

export default function Feed({ isLoggedIn, purpose, navigatePageToItem }) {
  console.log("purpose", purpose);
  const { userSettings, setUserSettings } = useContext(GlobalContext);
  let [searchParams, setSearchParams] = useSearchParams();
  const [items, setItems] = useState([]);
  // const [studyFeedType, setStudyFeedType] = useState("shuffle");
  const [studyFeedType, setStudyFeedType] = useState("algo");
  const [overduenessScore, setOverduenessScore] = useState([]);
  const [numOverdueItems, setNumOverdueItems] = useState([]);
  const [parentItem, setParentItem] = useState();
  const [showStudyPanel, setShowStudyPanel] = useState();
  const [topicItemId, setTopicItemId] = useState(searchParams.get("item_id"));
  const removeItemById = (itemId) => {
    const newItems = items.filter((item) => item.id !== itemId);
    setItems(newItems);
  };

  const [getItems, { loading, projectData: itemsData }] = useLazyQuery(FEED, {
    fetchPolicy: "no-cache",
    variables: {
      topicItemId: topicItemId ? topicItemId : "",
      feedType: studyFeedType,
      purpose: purpose,
    },
    onCompleted(data) {
      console.log("oncompleted mann", data);
      setItems(data.feed.items);
      setOverduenessScore(data.feed.overduenessScore);
      setNumOverdueItems(data.feed.numOverdueItems);
      setParentItem(data.feed.parentItem);
    },
    onError(err) {
      console.log("onerror", err);
    },
  });

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    console.log("useEffect studyFeedType", studyFeedType);
    getItems();
  }, [studyFeedType]);

  const onDragEnd = async (result) => {
    console.log("drag end!!!!!!!!!!!");
    // if (!result.destination) return;

    // const newItems = [...tasks];
    // const [removed] = newItems.splice(result.source.index, 1);
    // newItems.splice(result.destination.index, 0, removed);

    // setTasks(newItems);
  };

  if (loading)
    return (
      <div>
        {purpose === "news" ? "loading news bro..." : "loading study sesh..."}
      </div>
    );
  if (!isLoggedIn) return <Onboarding />;

  return (
    <>
      {purpose === "study" && (
        <>
          <div
            style={{
              paddingLeft: "15px",

              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <button
                style={{
                  marginLeft: "4px",
                  marginBottom: "10px",
                }}
                onClick={() => setShowStudyPanel((prev) => !prev)}
              >
                study details
              </button>
              <div
                style={{
                  // display: "flex",
                  // justifyContent: "space-between",
                  padding: "0.2rem",
                  marginLeft: "40px",
                }}
              >
                {parentItem?.name ? (
                  <>studying: {parentItem.name}</>
                ) : (
                  <>global study mode</>
                )}
              </div>
            </div>
            {showStudyPanel && (
              <>
                <StudyFeedTypeSwitch
                  setStudyFeedType={setStudyFeedType}
                  currentStudyFeedType={studyFeedType}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.5rem",
                  }}
                >
                  overdueness score: {overduenessScore}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.5rem",
                  }}
                >
                  num overdue items: {numOverdueItems}
                </div>
              </>
            )}
            {false && (
              // {userSettings.userId == 2 && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <span style={{ marginRight: 10 }}>new shit</span>
                  <input type="range" />
                  <span style={{ marginLeft: 10 }}>study</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <span style={{ marginRight: 10 }}>cheese</span>
                  <input type="range" />
                  <span style={{ marginLeft: 10 }}>dingleberry</span>
                </div>
              </>
            )}
          </div>
        </>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => (
                <Draggable
                  key={item.id.toString()}
                  draggableId={item.id ? item.id.toString() : ""}
                  index={index}
                >
                  {(provided) => (
                    <div
                      style={{
                        borderTop: "2px solid #191919", // Only a top border is applied
                        paddingTop: "15px",
                      }}
                    >
                      <ItemView
                        item={item}
                        loading={false}
                        error={undefined}
                        refetch={() => {}}
                        purpose={purpose}
                        removeItemById={removeItemById}
                        navigatePageToItem={navigatePageToItem}
                        // defaultToZenMode={purpose === "news"}
                        defaultToZenMode={true}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}
