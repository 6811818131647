import React from "react";

export default function Privacy() {
  return (
    <div>
      <h1>Privacy Policy</h1>

      <p>
        We respect your privacy and are committed to protecting your personal
        data. This policy outlines how we collect, use, and safeguard your
        information when you use our website.
      </p>

      <h4>1. Information We Collect</h4>
      <p>
        We may collect personal information such as your name, email address,
        and usage data when you interact with our website.
      </p>

      <h4>2. How We Use Your Information</h4>
      <p>
        We use your data to provide and improve our services, communicate with
        you, and comply with legal obligations.
      </p>

      <h4>3. Data Security</h4>
      <p>
        We implement appropriate technical and organizational measures to
        protect your personal information.
      </p>

      <h4>4. Third-Party Services</h4>
      <p>
        We may use third-party services that collect, monitor, and analyze data
        to improve our website's functionality.
      </p>

      <h4>5. Your Rights</h4>
      <p>
        You have the right to access, correct, or delete your personal data.
        Contact us for any privacy-related requests.
      </p>

      <h4>6. Changes to This Policy</h4>
      <p>
        We may update this privacy policy from time to time. We will notify you
        of any changes by posting the new policy on this page.
      </p>

      <p>
        For more information about our privacy practices, please contact us.
      </p>

      <p>
        <em>Last updated: August 14, 2024</em>
      </p>
    </div>
  );
}
