// ChildItem.js
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import ItemRatingInner from "./ItemRatingInner";
import DeleteItem from "./DeleteItem";
import ChildItemDisplayText from "./ChildItemDisplayText";
import ItemView from "./ItemView";

function floatToStringMaxThreeChars(num) {
  // Convert the number to a string
  let str = num.toString();

  // Check if the string is longer than 3 characters
  if (str.length > 3) {
    // If the number is an integer and longer than 3 digits, truncate it
    if (str.indexOf(".") === -1) {
      str = str.substring(0, 3);
    } else {
      // If the string contains a decimal point, check where it is
      let decimalIndex = str.indexOf(".");

      // If the decimal point is in the first three characters, return substring that includes it
      if (decimalIndex < 3) {
        str = str.substring(0, 3);
      } else {
        // If the decimal point is not within the first two characters, truncate before it
        str = str.substring(0, decimalIndex);
      }
    }
  }

  return str;
}

const ChildItem = ({
  childItem,
  index,
  userSettings,
  onDeleteItem,
  isPublicView,
  navigatePageToItem,
  contextIsSidebar = false,
  loading,
  draggable = false,
}) => {
  // return <div>itemid: {childItem.id}</div>
  const content = (
    <tr>
      <td
        style={
          {
            // padding: "5px",
          }
        }
      >
        <div
          // onClick={() => {
          //   if (typeof navigatePageToItem === "function") {
          //     navigatePageToItem(childItem.id);
          //   }
          // }}
          onClick={() => {
            if (contextIsSidebar) {
              navigatePageToItem(childItem.id);
            }
          }}
        >
          <ItemView
            item={childItem}
            // loading={itemDataLoading}
            // error={itemDataError}
            // setItemIdForItemViewPage={(newItemId) => {
            //   setItemId(newItemId);
            //   setPageNeedsReload(true);
            // }}
            // refetchItem={switchPageToNewItem}
            purpose={contextIsSidebar ? "child_item_for_sidebar" : "graph"}
            publicView={isPublicView}
            defaultToZenMode={true}
            navigatePageToItem={navigatePageToItem}
            loading={loading}
          />
          {/* <ChildItemDisplayText childItem={childItem} /> */}
        </div>
        {/* </Link> */}
      </td>
      {userSettings &&
      !userSettings.hideRatingsInMostPlaces &&
      !contextIsSidebar ? (
        <td
          style={{
            whiteSpace: "nowrap",
            padding: "5px",
          }}
        >
          <ItemRatingInner
            rating_type_for_display={""}
            rating_type={"importance"}
            item_id={childItem.id}
            existing_rating={childItem.importance}
          />
        </td>
      ) : (
        <></>
      )}
      <td>
        {childItem.isPublic === "true" && !isPublicView ? (
          <div className={`circle-button-inner is-public`}></div>
        ) : (
          <></>
        )}
      </td>
      <td>
        {childItem?.aggregateImportance
          ? floatToStringMaxThreeChars(childItem.aggregateImportance)
          : ""}
      </td>
      <td
        style={{
          whiteSpace: "nowrap",
          padding: "5px",
        }}
      >
        {userSettings?.userId == childItem.user?.id && (
          <DeleteItem
            displayText={"x"}
            item={childItem}
            onDelete={onDeleteItem}
            transparentBg={true}
          />
        )}
      </td>
    </tr>
  );
  return draggable ? (
    <Draggable
      key={childItem.id.toString()}
      draggableId={childItem.id.toString()}
      index={index}
    >
      {(provided) => (
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {content.props.children}
        </tr>
      )}
    </Draggable>
  ) : (
    content
  );

  return (
    <Draggable
      key={childItem.id.toString()}
      draggableId={childItem.id.toString()}
      index={index}
    >
      {(provided) => (
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        ></tr>
      )}
    </Draggable>
  );
};

export default ChildItem;
