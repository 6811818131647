import React, { useState, useCallback, useEffect, useRef } from "react";
import GoogleMapsView from "./components/GoogleMapsView";
import AudioPlayer from "./components/AudioPlayer";
import TourControls from "./components/TourControls";
import Onboarding from "./bamboo-components/Onboarding";
import DebugModal from "./components/DebugModal";
import StatsPane from "./components/StatsPane";
import { useMutation, useQuery } from "@apollo/client";
import PingStatusIndicator from "./components/PingStatusIndicator";
import NeedToPingIndicator from "./components/NeedToPingIndicator";
import * as utils from "./talkyMappyUtils"; // Adjust the path based on your file structure
import TourModal from "./components/TourModal";
import ItemModal from "./components/ItemModal";

import { CHECK_FOR_IN_PROGRESS_TOUR } from "./queries";
import { END_TOUR, START_TOUR, PING_LOCATION } from "./mutations";
const UNLOCK_RADIUS = 30;

const TEST_MODE = true;
// const TEST_MODE = false;

// 126 st marks
const EV_LAT = 40.7268939;
const EV_LON = -73.98378079999999;

const PING_CADENCE = 3000; // units are milliseconds
const COIN_SOUND_URL =
  "https://www.toplevel.work/audio/coin-sound-253-volume-point-four.mp3";

const Map = ({ isLoggedIn }) => {
  const [startTourMutation] = useMutation(START_TOUR);
  const [endTourMutation] = useMutation(END_TOUR);
  const {
    loading: checkInProgressLoading,
    error: checkInProgressError,
    data: checkInProgressData,
  } = useQuery(CHECK_FOR_IN_PROGRESS_TOUR);
  const [pingLocation] = useMutation(PING_LOCATION);
  const [onTour, setOnTour] = useState(false);
  const [tourId, setTourId] = useState(null);
  const [legs, setLegs] = useState([]);
  const [currentLeg, setCurrentLeg] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [location, setLocation] = useState({ lat: null, lon: null });
  const [pingStatus, setPingStatus] = useState("");
  const [shouldStopAudio, setShouldStopAudio] = useState(false);

  const [closestStep, setClosestStep] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDebugModalOpen, setIsDebugModalOpen] = useState(false);
  const [waypoints, setWaypoints] = useState([]); // New state variable for waypoints
  const [stepDistances, setStepDistances] = useState([]);
  const [needToPing, setNeedToPing] = useState(false);
  // const [isTourModalOpen, setIsTourModalOpen] = useState(true);
  const [isTourModalOpen, setIsTourModalOpen] = useState(false);
  // const [isItemModalOpen, setIsItemModalOpen] = useState(true);
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);
  const [itemForItemModal, setItemForItemModal] = useState();

  const [legAudioSequence, setLegAudioSequence] = useState([]);
  const [interruptAudioList, setInterruptAudioList] = useState([]);

  // const [isItemModalOpen, setIsItemModalOpen] = useState(false);
  const [directions, setDirections] = useState();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newLocation = {
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          };
          setLocation(newLocation);
        },
        (error) => {
          console.error("Error getting initial location:", error);
        }
      );
    }
  }, []);
  const toggleStartTourModal = () => {
    setIsTourModalOpen(!isTourModalOpen);
  };

  useEffect(() => {
    if (checkInProgressLoading) return;

    if (checkInProgressError) {
      console.error(
        "Error checking for in-progress tour:",
        checkInProgressError
      );
      return;
    }

    if (checkInProgressData && checkInProgressData.checkForInProgressTour) {
      const response = checkInProgressData.checkForInProgressTour;

      if (response.legs) {
        utils.maybeSetDirections(response, directions, setDirections);

        setOnTour(true);
        console.log("legs my good man 1");
        console.log(response.legs);
        setLegs(response.legs);
        setTourId(response.tourId);
        setCurrentLeg(response.currentLegIndex);
        setCurrentStep(response.currentStepIndex);
        setWaypoints(
          utils.extractWaypoints(response.legs, currentLeg, currentStep)
        );
      } else {
        setOnTour(false);
        console.warn("no legs");
      }
    }
  }, [checkInProgressLoading, checkInProgressError, checkInProgressData]);

  const handleLocationChange = (pos) => {
    const newLocation = {
      lat: parseFloat(pos.lat),
      lon: parseFloat(pos.lng),
    };
    setLocation(newLocation);
    if (onTour && tourId) {
      const { newStepDistances, newClosestStep } =
        utils.calculateNewStepDistances(legs, newLocation);
      setStepDistances(newStepDistances);
      setClosestStep(newClosestStep);
      if (newClosestStep && newClosestStep.distance < UNLOCK_RADIUS) {
        if (
          newClosestStep.legIndex !== currentLeg ||
          newClosestStep.stepIndex !== currentStep
        ) {
          setCurrentLeg(newClosestStep.legIndex);
          setCurrentStep(newClosestStep.stepIndex);
          setNeedToPing(true); // Reset this flag to trigger new pings

          // I don't think this is neccessary for now since waypoints are generally set at the beginning of the tour and never again
          // setWaypoints(
          //   utils.extractWaypoints(
          //     legs,
          //     newClosestStep.legIndex,
          //     newClosestStep.stepIndex
          //   )
          // );
        }
      }
      setLocation(newLocation);
    }
  };

  useEffect(() => {
    if (currentLeg !== null && currentStep !== null && legs.length > 0) {
      if (currentStep == 0) {
        const sequenceAudioUrls = legs[currentLeg].audioUrls;
        if (sequenceAudioUrls && sequenceAudioUrls.length > 0) {
          setLegAudioSequence([COIN_SOUND_URL, ...sequenceAudioUrls]);
        } else {
          alert("No sequence audio available!");
        }
      } else {
        const stepAudioUrl = legs[currentLeg].steps[currentStep].audioUrl;
        if (stepAudioUrl) {
          console.log("Interrupting with new step audio");
          setInterruptAudioList([COIN_SOUND_URL, stepAudioUrl]);
        } else {
          alert("Want to play an audio file but there isn't one available!");
        }
      }
    }
  }, [currentLeg, currentStep]);

  const ping = useCallback(() => {
    if (!TEST_MODE && (!tourId || !location.lat || !location.lon)) {
      console.error("Missing tourId or location");
      return Promise.reject("Missing tourId or location");
    }
    setPingStatus("initiating");
    return pingLocation({
      variables: {
        tourId: String(tourId),
        lat: TEST_MODE ? EV_LAT : parseFloat(location.lat),
        lon: TEST_MODE ? EV_LON : parseFloat(location.lon),
        currentLegIndex: currentLeg,
        currentStepIndex: currentStep,
      },
    })
      .then((response) => {
        setPingStatus("completed");
        setTimeout(() => {
          setPingStatus("");
        }, 3000);
        return response.data.pingLocation;
      })
      .catch((error) => {
        setPingStatus("");
        console.error("Error during ping:", error);
      });
  }, [pingLocation, tourId, location]);

  const pingUntilReady = useCallback(() => {
    let pingCount = 0;
    const maxPings = 20; // Increased max pings to allow for more attempts

    const pingInterval = setInterval(() => {
      if (pingCount >= maxPings) {
        clearInterval(pingInterval);
        console.log("Maximum number of pings reached. Stopping.");
        alert("Maximum number of pings reached. Stopping.");
        return;
      }

      pingCount++;
      ping()
        .then((response) => {
          console.log("\n\n__________ Checking tour status __________________");

          utils.maybeSetDirections(response, directions, setDirections);

          if (response.legs && response.legs.length > 0) {
            console.log("legs my good man 0");
            console.log(response.legs);
            setLegs(response.legs);
            setWaypoints(
              utils.extractWaypoints(response.legs, currentLeg, currentStep)
            );

            const firstStepAudio = response.legs[0].steps[0].audioUrl;
            if (firstStepAudio && currentLeg === null && currentStep === null) {
              // setting these should trigger the firstAudio to be played
              setCurrentLeg(0);
              setCurrentStep(0);
            }

            if (
              utils.checkNextStepsHaveAudio(
                legs,
                currentLeg,
                currentStep,
                4 // this is inclusive of the current step, so it's 3 in the future
              ) ||
              utils.checkAllStepsHaveAudio(legs)
            ) {
              clearInterval(pingInterval);
              console.log("Next two steps are loaded. Stopping pings.");
              setNeedToPing(false);
            } else {
              console.log("Next two steps not loaded yet. Continuing to ping.");
            }
          } else {
            console.log(`Tour not ready yet, ping ${pingCount}/${maxPings}`);
          }
        })
        .catch((error) => {
          console.error("Error during ping:", error);
          clearInterval(pingInterval);
        });
    }, PING_CADENCE);

    return () => clearInterval(pingInterval);
  }, [
    ping,
    setCurrentLeg,
    setCurrentStep,
    setLegs,
    setWaypoints,
    legs,
    currentLeg,
    currentStep,
  ]);

  const handleStartTourFromModal = (
    address,
    prompt,
    modeOfTransport,
    stops
  ) => {
    console.log(`starting tour with address: ${address}  prompt: ${prompt}`);
    setIsTourModalOpen(false);
    startTour(address, prompt, modeOfTransport, stops);
  };

  const startTour = (
    address,
    prompt,
    modeOfTransport,
    stopsAsString,
    stopsAsItemIds
  ) => {
    setIsLoading(true);
    console.log("starting tour");
    if (!TEST_MODE) {
      if (location.lat === null || location.lon === null) {
        console.error("Invalid location data. Cannot start tour.");
        alert(
          "Unable to start tour. Please ensure your location is available."
        );
        setIsLoading(false);
        return;
      }
    }
    startTourMutation({
      variables: {
        lat: TEST_MODE ? EV_LAT : parseFloat(location.lat),
        lon: TEST_MODE ? EV_LON : parseFloat(location.lon),
        address: address,
        prompt: prompt,
        modeOfTransport: modeOfTransport,
        stops: stopsAsString,
        stopsAsItemIds: stopsAsItemIds != null ? stopsAsItemIds : [],
      },
    })
      .then((response) => {
        const { audioS3Url, tourId: newTourId } = response.data.startTour;
        setInterruptAudioList([audioS3Url]);
        setTourId(newTourId);
        setOnTour(true);
        setNeedToPing(true);
      })
      .catch((error) => {
        console.error("Error starting tour:", error);
        alert("Failed to start the tour. Please try again.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (onTour && tourId && needToPing && !utils.checkAllStepsHaveAudio(legs)) {
      const cleanup = pingUntilReady();
      return cleanup;
    }
  }, [onTour, tourId, needToPing, pingUntilReady]);

  const endTour = () => {
    console.log(`ending the tour: ${tourId}`);
    endTourMutation({
      variables: {
        tourId: tourId,
      },
    })
      .then((response) => {
        setTourId(null);
        setOnTour(false);
        setLegs([]);
        setCurrentLeg(null);
        setCurrentStep(null);
        setWaypoints([]);
        setDirections(null);
        setShouldStopAudio(true);
        setIsTourModalOpen(false);
      })
      .catch((error) => {
        console.error("Error ending tour:", error);
        alert("Failed to end the tour. Please try again.");
      });
  };

  const toggleDebugModal = () => {
    setIsDebugModalOpen(!isDebugModalOpen);
  };
  if (!isLoggedIn) return <Onboarding />;

  const openItemModal = (item) => {
    setIsItemModalOpen(true);
    setItemForItemModal(item);
  };

  return (
    <div style={{ position: "relative", height: "100vh" }}>
      <GoogleMapsView
        onLocationChange={handleLocationChange}
        waypoints={waypoints}
        directions={directions}
        onItemMarkerClick={openItemModal}
        onTour={onTour}
      />
      <TourControls
        onTour={onTour}
        isLoading={isLoading}
        toggleStartTourModal={toggleStartTourModal}
      />
      {pingStatus && <PingStatusIndicator status={pingStatus} />}
      {needToPing && <NeedToPingIndicator status={needToPing} />}
      <AudioPlayer
        legAudioSequence={legAudioSequence}
        interruptAudioList={interruptAudioList} // Corrected prop name
        onInterruptComplete={() => setInterruptAudioList([])}
        shouldStop={shouldStopAudio}
        onStopComplete={() => {
          setShouldStopAudio(false);
        }}
      />

      {onTour && (
        <div
          style={{
            position: "absolute",
            bottom: 10,
            left: 10,
            backgroundColor: "white",
            padding: 10,
          }}
        >
          <p>Current Leg: {currentLeg}</p>
          <p>Current Step: {currentStep}</p>
        </div>
      )}
      <button
        onClick={toggleDebugModal}
        style={{
          zIndex: 10,
          padding: "10px 20px",
          backgroundColor: isLoading ? "#ccc" : "white",
          border: "none",
          boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
          cursor: isLoading ? "not-allowed" : "pointer",
          opacity: isLoading ? 0.7 : 1,
          position: "absolute",
          top: 10,
          right: 10,
          zIndex: 1000,
        }}
      >
        debug
      </button>
      {onTour && (
        <StatsPane
          currentLeg={currentLeg}
          currentStep={currentStep}
          legs={legs}
        />
      )}
      <DebugModal
        isOpen={isDebugModalOpen}
        onClose={toggleDebugModal}
        location={location}
        onTour={onTour}
        tourId={tourId}
        currentLeg={currentLeg}
        currentStep={currentStep}
        waypoints={waypoints}
        stepDistances={stepDistances}
        closestStep={closestStep}
        legs={legs}
        tourFullyLoaded={needToPing}
      />
      {isTourModalOpen && (
        <TourModal
          onClose={() => setIsTourModalOpen(false)}
          onStartTour={handleStartTourFromModal}
          tourId={tourId}
          location={location}
          onTour={onTour}
          endTour={endTour}
        />
      )}
      {isItemModalOpen && (
        <ItemModal
          onClose={() => setIsItemModalOpen(false)}
          item={itemForItemModal}
        />
      )}
    </div>
  );
};

export default Map;
