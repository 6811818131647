import { useQuery, gql } from "@apollo/client";
import { useEffect } from "react";

const GET_USER_SETTINGS = gql`
  query {
    userSettings {
      userId
      user {
        darkModeEnabled
        showFineGrainedRatingsInStudyView
        hideRatingsInMostPlaces
        sendDailyNewsDigest
      }
    }
  }
`;

function UserSettingsManager({ setUserSettings }) {
  const { data, loading, error } = useQuery(GET_USER_SETTINGS);
  useEffect(() => {
    document.body.classList.add("dark-mode");
  }, []);

  useEffect(() => {
    if (loading || error || !data || !data.userSettings) {
      return;
    }

    if (data.userSettings.user.darkModeEnabled) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }

    setUserSettings({
      showFineGrainedRatingsInStudyView:
        data.userSettings.user.showFineGrainedRatingsInStudyView,
      hideRatingsInMostPlaces: data.userSettings.user.hideRatingsInMostPlaces,
      darkModeEnabled: data.userSettings.user.darkModeEnabled,
      sendDailyNewsDigest: data.userSettings.user.sendDailyNewsDigest,
      userId: data.userSettings.userId,
    });
  }, [data]);

  // This component is mostly for side-effect logic, not rendering.
  return null;
}

export default UserSettingsManager;
