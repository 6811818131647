import React, { useRef, useEffect } from "react";

const ChatHistory = ({ messages }) => {
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const chatStyle = {
    height: "100%",
    overflowY: "auto",
    marginBottom: "10px",
    padding: "10px",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  };

  const messageStyle = (author) => ({
    marginBottom: "12px",
    maxWidth: "80%",
    wordWrap: "break-word",
    lineHeight: "1.5",
    alignSelf: author === "user" ? "flex-end" : "flex-start",
    textAlign: author === "user" ? "right" : "left",
    color: author === "user" ? "#8A8888" : "inherit", // Pink color for user messages
  });

  return (
    <div ref={chatContainerRef} style={chatStyle}>
      {messages.map((message, index) => (
        <div key={index} style={messageStyle(message.author)}>
          {message.text}
        </div>
      ))}
    </div>
  );
};

export default ChatHistory;
