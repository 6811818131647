import React from "react";

const StatsPane = ({ legs, currentLeg, currentStep }) => {
  const calculatePreviousSteps = () => {
    let stepCount = 0;
    for (let legIndex = 0; legIndex < legs.length; legIndex++) {
      for (
        let stepIndex = 0;
        stepIndex < legs[legIndex].steps.length;
        stepIndex++
      ) {
        if (
          legIndex < currentLeg ||
          (legIndex === currentLeg && stepIndex <= currentStep)
        ) {
          stepCount++;
        } else {
          return stepCount;
        }
      }
    }
    return stepCount;
  };

  const previousSteps = Math.max(0, calculatePreviousSteps() - 1); // zero-th step doesn't count

  return (
    <div
      style={{
        zIndex: 10,
        padding: "10px 20px",
        backgroundColor: "white",
        border: "none",
        boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
        opacity: 1,
        position: "absolute",
        top: 70,
        right: 10,
        zIndex: 1000,
        width: 88,
        height: 60,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          width: 20,
          height: 20,
          borderRadius: "50%",
          backgroundColor: "yellow",
        }}
      ></div>
      <span style={{ fontSize: 18 }}>{previousSteps}</span>
    </div>
  );
};

export default StatsPane;
