import React from "react";

const TourControls = ({ onTour, isLoading, toggleStartTourModal }) => {
  const buttonStyle = {
    position: "absolute",
    top: 10,
    left: 10,
    zIndex: 10,
    padding: "10px 20px",
    backgroundColor: onTour ? "#ffe8d9" : isLoading ? "#ccc" : "white",
    border: "none",
    boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
    cursor: isLoading ? "not-allowed" : "pointer",
    opacity: isLoading ? 0.7 : 1,
  };

  return (
    <button
      onClick={toggleStartTourModal}
      style={buttonStyle}
      id="open-start-tour-modal-button"
    >
      tour
    </button>
  );
};

export default TourControls;
