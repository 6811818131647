import React, { useRef, useEffect, useState } from "react";
import { useMutation, gql } from "@apollo/client";

export default function ChildrenViaGptManager({
  item,
  throwTheNewKidsIntoThisFunction,
}) {
  const promptInputRef = useRef();
  const makeNewKidsButtonRef = useRef();
  const makeNewKidsAsStudyItemsButtonRef = useRef();

  const MAKE_KIDS = gql`
    mutation createChildrenViaGpt(
      $itemId: String!
      $prompt: String!
      $makeEmStudyItems: Boolean
    ) {
      createChildrenViaGpt(
        itemId: $itemId
        prompt: $prompt
        makeEmStudyItems: $makeEmStudyItems
      ) {
        newKids {
          id
          name
          text
        }
      }
    }
  `;
  const [
    makeKidsMutation,
    { data: newKidsData, loading: newKidsLoading, error: newKidsError },
  ] = useMutation(MAKE_KIDS);

  useEffect(() => {
    if (newKidsData && newKidsData.createChildrenViaGpt.newKids) {
      throwTheNewKidsIntoThisFunction(newKidsData.createChildrenViaGpt.newKids);
    }
  }, [newKidsData]);

  useEffect(() => {
    makeNewKidsButtonRef.current.disabled = newKidsLoading;
    makeNewKidsAsStudyItemsButtonRef.current.disabled = newKidsLoading;
    makeNewKidsButtonRef.current.innerHTML = newKidsLoading
      ? "hang on.."
      : "make new kids";
    makeNewKidsAsStudyItemsButtonRef.current.innerHTML = newKidsLoading
      ? "hang on.."
      : "make new kids as study items";
  }, [newKidsLoading]);

  function handleSubmit(makeEmStudyItems = false) {
    console.log("make em", makeEmStudyItems);
    let prompt = promptInputRef.current.value;
    if (prompt == "" && !makeEmStudyItems) {
      alert("please enter a prompt!");
      return;
    }

    makeKidsMutation({
      variables: {
        itemId: item.id,
        prompt: prompt,
        makeEmStudyItems: makeEmStudyItems,
      },
      onCompleted(data) {
        if (makeEmStudyItems) {
          alert("we're working on it in the background! we'll text you when we're done.");
        }
      },
      onError(err) {
        console.log("error makin kids:", err);
        alert("error makin kids!");
      },
    });
    promptInputRef.current.value = "";
  }

  return (
    <>
      <div
        style={{ padding: "30px", display: "flex", flexDirection: "column" }}
      >
        <textarea
          ref={promptInputRef}
          style={{
            padding: "10px",
            width: "90%",
            height: "120px",
            maxWidth: "600px",
          }}
        />
        <div
          style={{
            padding: "5px",
          }}
        >
          <button
            style={{
              width: "140px",
            }}
            onClick={() => handleSubmit(false)}
            className="m-2"
            ref={makeNewKidsButtonRef}
          ></button>
          <button
            style={{
              width: "240px",
            }}
            onClick={() => handleSubmit(true)}
            className="m-2"
            ref={makeNewKidsAsStudyItemsButtonRef}
          ></button>
        </div>
        <br></br>
      </div>
    </>
  );
}
