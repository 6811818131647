import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import Upload from "./Upload";

const UPLOAD_MARKDOWN_FILE = gql`
  mutation uploadMarkdownFile($fileContent: String!) {
    uploadMarkdownFile(fileContent: $fileContent) {
      result
    }
  }
`;

const UPLOAD_READWISE_EXPORT_FILE = gql`
  mutation uploadReadwiseExportFile($fileContent: String!) {
    uploadReadwiseExportFile(fileContent: $fileContent) {
      result 
    }
  }
`;

const UPLOAD_EPUB_FILE = gql`
  mutation uploadEpubFile($fileContent: String!) {
    uploadEpubFile(fileContent: $fileContent) {
      result 
    }
  }
`;

export default function UploadPage() {
  return (
    <div >
      <Upload uploadType={"markdown"} gqlMutation={UPLOAD_MARKDOWN_FILE}/>
      <Upload uploadType={"readwise"} gqlMutation={UPLOAD_READWISE_EXPORT_FILE}/>
      <Upload uploadType={"e-pub"} gqlMutation={UPLOAD_EPUB_FILE}/>
    </div>
  );
}

