import React, { useState, useCallback } from "react";
import ChatComponent from "./ChatComponent";

const TourModal = ({
  onClose,
  onStartTour,
  onTour,
  endTour,
  tourId,
  location,
}) => {
  const [modeOfTransport, setModeOfTransport] = useState("walk");
  const [view, setView] = useState("chat");
  const Tour = () => <div>Tour component (to be implemented)</div>;

  const handleOutsideClick = useCallback(
    (e) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    },
    [onClose]
  );

  const modalStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  };

  const contentStyle = {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
    width: "80%",
    maxWidth: "none",
    display: "flex",
    flexDirection: "column",
    height: "95%",
  };

  const lightGrayColor = "#8A8888";

  const buttonStyle = {
    padding: "10px 20px",
    border: "none",
    borderRadius: "3px",
    cursor: "pointer",
    marginLeft: "10px",
    backgroundColor: "white",
    boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
    color: lightGrayColor,
  };

  const radioLabelStyle = {
    marginRight: "10px",
    display: "inline-flex",
    alignItems: "center",
    color: lightGrayColor,
  };

  const radioInputStyle = {
    marginRight: "5px",
    accentColor: lightGrayColor,
  };

  const switchStyle = {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  };

  const switchButtonStyle = (isActive) => ({
    padding: "10px 20px",
    border: "none",
    backgroundColor: "transparent",
    color: isActive ? "#333333" : "#CCCCCC",
    cursor: "pointer",
    fontSize: "16px",
    transition: "color 0.3s ease",
  });

  const controlGroupStyle = {
    display: "flex",
    alignItems: "center",
    marginTop: "2px",
  };

  const handleTourButtonClick = () => {
    if (onTour) {
      console.log("Ending tour");
      endTour();
    } else {
      console.log("Starting tour");
      onStartTour(null, "", modeOfTransport, "");
    }
  };

  return (
    <div style={modalStyle} onClick={handleOutsideClick}>
      <div style={contentStyle}>
        <div style={switchStyle}>
          <button
            style={switchButtonStyle(view === "chat")}
            onClick={() => setView("chat")}
          >
            chat
          </button>
          <button
            style={switchButtonStyle(view === "tour")}
            onClick={() => setView("tour")}
          >
            tour
          </button>
        </div>
        <div style={{ flex: 1, overflowY: "auto", height: "100%" }}>
          {view === "chat" ? (
            <ChatComponent tourId={tourId} location={location} />
          ) : (
            <Tour />
          )}
        </div>
        <div style={controlGroupStyle}>
          <label style={radioLabelStyle}>
            <input
              type="radio"
              value="walk"
              checked={modeOfTransport === "walk"}
              onChange={(e) => setModeOfTransport(e.target.value)}
              style={radioInputStyle}
            />
            walk
          </label>
          <label style={radioLabelStyle}>
            <input
              type="radio"
              value="bike"
              checked={modeOfTransport === "bike"}
              onChange={(e) => setModeOfTransport(e.target.value)}
              style={radioInputStyle}
            />
            bike
          </label>
          <button
            id="start-tour-button"
            onClick={handleTourButtonClick}
            style={buttonStyle}
          >
            {onTour ? "end tour" : "start tour"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TourModal;
