import React from 'react';

const PingStatusIndicator = ({ status }) => {
  const getColor = () => {
    switch (status) {
      case 'initiating':
        return '#0000FF'; // Blue
      case 'inProgress':
        return '#FFFF00'; // Yellow
      case 'completed':
        return '#00FF00'; // Green
      default:
        return '#808080'; // Grey for undefined status
    }
  };

  return (
    <div style={{
      height: '20px',
      width: '20px',
      borderRadius: '50%',
      backgroundColor: getColor(),
      position: "absolute",
      top: 15, // Adjust top and left as necessary
      left: 140, // Position it to the right of the "start tour"/"end tour" button
      zIndex: 11, // Higher than the button to ensure visibility
    }} />
  );
};

export default PingStatusIndicator;

