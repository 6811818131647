import React, { useState, useEffect } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { SAVE_ITEM_RATING } from "../mutations";

export default function ItemRatingInner({
  rating_type,
  rating_type_for_display: ratingTypeForDisplay,
  item_id,
  existing_rating: existingRating,
  updateRatingCallback,
  squareButtons,
}) {
  const [currentRating, setCurrentRating] = useState(existingRating);

  const [mutateFunction, { data, loading, error }] =
    useMutation(SAVE_ITEM_RATING);

  function isButtonBold(button_number) {
    if (currentRating === null) {
      return false;
    }
    return button_number <= currentRating;
  }

  function handleSaveRating(rating) {
    mutateFunction({
      variables: {
        itemId: item_id,
        ratingType: rating_type,
        rating: rating,
      },
    });
    setCurrentRating(rating);
    setTimeout(updateRatingCallback, 500); // setting a timeout because otherwise it's reloading too fast and there's not enough time for the rating to save to the db and result in a new study item being available
  }

  useEffect(() => {
    // Whenever the item_id changes, we need to set the current rating back to what was passed in as the prop
    setCurrentRating(existingRating);
  }, [item_id]);
  return (
    <div
      style={{
        whiteSpace: "nowrap",
        margin: "4px",
      }}
    >
      {ratingTypeForDisplay != "" && (
        <span
          style={{
            width: ratingTypeForDisplay === "" ? "1px" : "150px",
            display: "inline-block",
          }}
        >
          {ratingTypeForDisplay}
        </span>
      )}
      <button
        onClick={() => handleSaveRating(0)}
        className={`${
          squareButtons
            ? "square-feedback-button"
            : "small-square-feedback-button"
        } ${isButtonBold(0) ? "feedback-button-bold" : ""}`}
      ></button>
      <button
        onClick={() => handleSaveRating(1)}
        className={`${
          squareButtons
            ? "square-feedback-button"
            : "small-square-feedback-button"
        } ${isButtonBold(1) ? "feedback-button-bold" : ""}`}
      ></button>
      <button
        onClick={() => handleSaveRating(2)}
        className={`${
          squareButtons
            ? "square-feedback-button"
            : "small-square-feedback-button"
        } ${isButtonBold(2) ? "feedback-button-bold" : ""}`}
      ></button>
      <button
        onClick={() => handleSaveRating(3)}
        className={`${
          squareButtons
            ? "square-feedback-button"
            : "small-square-feedback-button"
        } ${isButtonBold(3) ? "feedback-button-bold" : ""}`}
      ></button>
      <button
        onClick={() => handleSaveRating(4)}
        className={`${
          squareButtons
            ? "square-feedback-button"
            : "small-square-feedback-button"
        } ${isButtonBold(4) ? "feedback-button-bold" : ""}`}
      ></button>
      <button
        onClick={() => handleSaveRating(5)}
        className={`${
          squareButtons
            ? "square-feedback-button"
            : "small-square-feedback-button"
        } ${isButtonBold(5) ? "feedback-button-bold" : ""}`}
      ></button>
    </div>
  );
}
