import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";

const PERFORM_ACTION = gql`
  mutation PerformActionOnItem($itemId: String!, $action: String!) {
    performActionOnItem(itemId: $itemId, action: $action) {
      result
      newValue
    }
  }
`;

export default function PerformActionOnItemButton({
  item,
  displayText,
  action,
  onActionSuccess,
}) {
  const [performActionOnItem, { loading, error }] = useMutation(PERFORM_ACTION);
  const [isDisabledBecauseOfSuccess, setIsDisabledBecauseOfSuccess] =
    useState(false);

  const handlePerform = async () => {
    if (isDisabledBecauseOfSuccess) return;

    try {
      const { data } = await performActionOnItem({
        variables: { itemId: item.id, action: action },
      });
      if (data.performActionOnItem.result === "success") {
        alert("successfully performed action");
        setIsDisabledBecauseOfSuccess(true);
        if (onActionSuccess) {
          onActionSuccess(item.id);
        }
      } else {
        alert(
          data.performActionOnItem.message ||
            "An error occurred while performing action."
        );
      }
    } catch (error) {
      console.error("Failed to perform action:", error);
      alert("Failed to perform action: " + error);
    }
  };

  return (
    <>
      <button
        className="control-panel-button-rectangular"
        onClick={handlePerform}
        // disabled={loading || isDisabledBecauseOfSuccess}
        disabled={loading}
      >
        {/* {isDisabledBecauseOfSuccess ? "success!" : loading ? "hang on.." : displayText} */}
        {loading ? "hang on.." : displayText}
      </button>
      {/* {error && <p>error performing action!</p>} */}
    </>
  );
}
