// mutations.js
import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      success
      errors
      token
      unarchiving
      user {
        id
        username
      }
    }
  }
`;

export const TRIGGER_MAGIC_LINK_EMAIL = gql`
  mutation TriggerMagicLinkEmail($email: String!) {
    triggerMagicLinkEmail(email: $email) {
      result
    }
  }
`;

export const CREATE_FIRST_TASK_AND_ACCOUNT = gql`
  mutation CreateFirstTaskAndAccount($taskDescription: String!) {
    createFirstTaskAndAccount(taskDescription: $taskDescription) {
      success
      username
      password
    }
  }
`;

export const SAVE_ITEM_RATING = gql`
  mutation ItemRating(
    $itemId: String!
    $ratingType: String!
    $rating: String!
  ) {
    itemRating(itemId: $itemId, ratingType: $ratingType, rating: $rating) {
      item {
        id
        importance
      }
    }
  }
`;

export const CAPTURE = gql`
  mutation capture(
    $inputText: String!
    $action: String!
    $extantItemId: String
  ) {
    capture(
      inputText: $inputText
      action: $action
      extantItemId: $extantItemId
    ) {
      item {
        id
        name
        text
        href
        access
        parentItems {
          id
          name
          text
          isPublic
          importance
          href
        }
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser(
    $username: String!
    $bio: String!
    $isNpc: Boolean!
    $password: String!
    $email: String!
    $phone: String!
  ) {
    createUser(
      username: $username
      bio: $bio
      isNpc: $isNpc
      password: $password
      email: $email
      phone: $phone
    ) {
      success
      message
      user {
        username
        bio
        isNpc
        email
        phone
      }
    }
  }
`;

export const PING_LOCATION = gql`
  mutation PingLocation(
    $tourId: String!
    $lat: Float!
    $lon: Float!
    $currentLegIndex: String
    $currentStepIndex: String
  ) {
    pingLocation(
      tourId: $tourId
      lat: $lat
      lon: $lon
      currentLegIndex: $currentLegIndex
      currentStepIndex: $currentStepIndex
    ) {
      result
      directionsJson
      legs {
        audioUrls
        steps {
          audioUrl
          text
          startLocation {
            lat
            lon
          }
          endLocation {
            lat
            lon
          }
        }
      }
    }
  }
`;

export const START_TOUR = gql`
  mutation StartTour(
    $lat: Float!
    $lon: Float!
    $address: String
    $prompt: String
    $modeOfTransport: String
    $stops: String
    $stopsAsItemIds: [String]
  ) {
    startTour(
      lat: $lat
      lon: $lon
      address: $address
      prompt: $prompt
      modeOfTransport: $modeOfTransport
      stops: $stops
      stopsAsItemIds: $stopsAsItemIds
    ) {
      result
      audioS3Url
      tourId
    }
  }
`;

export const END_TOUR = gql`
  mutation EndTour($tourId: Int!) {
    endTour(tourId: $tourId) {
      result
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation SendMessage(
    $tourId: Int
    $message: String!
    $lat: Float!
    $lon: Float!
  ) {
    sendMessage(tourId: $tourId, message: $message, lat: $lat, lon: $lon) {
      result
      response
    }
  }
`;
export const SEND_MESSAGE_FOR_TOKO = gql`
  mutation SendMessageForToko($message: String!) {
    sendMessageForToko(message: $message) {
      result
      response
    }
  }
`;
