import React from "react";
import { formatDistanceToNow, parseISO } from "date-fns";
import { Link } from "react-router-dom";

export default function StudyDetails({ item }) {
  function TimeAgo(props) {
    if (props.date == undefined) {
      return <></>;
    }
    let date = parseISO(props.date);
    let timeAgo = formatDistanceToNow(date);
    return (
      <div className="m-4">
        <span> {timeAgo} </span> ago
      </div>
    );
  }
  return (
    <>
      <div
        style={{
          padding: "5px",
        }}
      >
        study score: {item.studyScore}
      </div>
      <div
        style={{
          padding: "5px",
        }}
      >
        overdueness: {item.overdueness}
      </div>
      <div
        style={{
          padding: "5px",
        }}
      >
        days since last study: {item.daysSinceLastStudy}
      </div>
      <div
        style={{
          padding: "5px",
        }}
      >
        snooze time: {item.snoozeTime}
      </div>
      highlighted: <TimeAgo date={item.highlightedAt} />
      created: <TimeAgo date={item.createdAt} />
      item id: <Link to={`/item?item_id=${item.id}`}> {item.id} </Link>
    </>
  );
}
