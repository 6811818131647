import React, { useState, useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import CreateUser from "./components/CreateUser";

const USERS = gql`
  query users {
    users {
      edges {
        node {
          username
          isNpc
          sendDailyNewsDigest
          redirectMessages 
          email
          bio
          phone
        }
      }
    }
  }
`;

// only creating this bc I can't figure out how to add new fields to query on the CustomUserType
const USER_STATS = gql`
  query usersStats {
    userStats {
      username
      totalNumItems
      totalNumRecallEvents
      numItemsLastWeek
      numRecallEventsLastWeek
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteUser($username: String!) {
    deleteUser(username: $username) {
      success
      message
    }
  }
`;

export default function Users() {
  const [users, setUsers] = useState([]);
  const [userStats, setUserStats] = useState([]);

  const {
    loading,
    data: itemsData,
    error: usersError,
  } = useQuery(USERS, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      setUsers(data.users.edges);
    },
    onError(err) {
      alert(`Error fetching users: ${err.message}`);
    },
  });

  const {
    statsLoading,
    data: statsData,
    error: statsError,
  } = useQuery(USER_STATS, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      setUserStats(data.userStats);
    },
    onError(err) {
      alert(`Error fetching user stats: ${err.message}`);
    },
  });

  const [deleteUser, { error: deleteUserError }] = useMutation(DELETE_USER, {
    refetchQueries: [{ query: USERS }],
    onCompleted(data) {
      if (!data.deleteUser.success) {
        alert(`Error deleting user: ${data.deleteUser.message}`);
      }
    },
    onError(err) {
      alert(`Error deleting user: ${err.message}`);
    },
  });

  const handleDelete = (username) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (isConfirmed) {
      deleteUser({ variables: { username } });
    }
  };

  useEffect(() => {
    if (deleteUserError) {
      alert(`Error deleting user: ${deleteUserError.message}`);
    }
  }, [deleteUserError]);

  if (loading) return <p>Loading...</p>;
  if (usersError) return <p>Error: {usersError.message}</p>;

  return (
    <div>
      <table border="1">
        <thead>
          <tr>
            <th>username</th>
            <th>is npc</th>
            <th>email</th>
            <th>bio</th>
            <th>phone</th>
            <th>send_daily_news_digest</th>
            <th>redirect_messages </th>
            <th>delete user</th>
          </tr>
        </thead>
        <tbody>
          {users.map(({ node }) => (
            <tr key={node.username}>
              <td>{node.username}</td>
              <td>{node.isNpc.toString()}</td>
              <td>{node.email}</td>
              <td>{node.bio}</td>
              <td>{node.phone}</td>
              <td>{node.sendDailyNewsDigest.toString()}</td>
              <td>{node.redirectMessages.toString()}</td>
              <td>
                <button onClick={() => handleDelete(node.username)}>x</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <br></br>
      <table border="1">
        <thead>
          <tr>
            <th>username</th>
            <th>total items</th>
            <th>num recall events</th>
            <th>num items last week</th>
            <th>num recall events last week</th>
          </tr>
        </thead>
        <tbody>
          {userStats &&
            userStats.map((user) => (
              <tr key={user.username}>
                <td>{user.username}</td>
                <td>{user.totalNumItems}</td>
                <td>{user.totalNumRecallEvents}</td>
                <td>{user.numItemsLastWeek}</td>
                <td>{user.numRecallEventsLastWeek}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <br></br>
      <CreateUser />
    </div>
  );
}
