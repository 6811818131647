import React, { useState, useRef } from "react";
import { useQuery, gql } from "@apollo/client";
import ChildItems from "./components/ChildItems";
import { SEARCH_QUERY } from "./queries";

export default function SearchPage() {
  const searchInputRef = useRef();
  const executeButtonRef = useRef();
  const [query, setQuery] = useState("");
  const {
    data: searchData,
    loading: searchLoading,
    error: searchError,
    refetch,
  } = useQuery(SEARCH_QUERY, {
    variables: { query },
    skip: !query,
  });

  const items = searchData?.searchItems;

  function handleSubmitSearch(e) {
    let queryValue = searchInputRef.current.value;
    searchInputRef.current.innerHTML = "";
    if (queryValue === "") {
      return;
    }

    refetch({ query: queryValue });
    searchInputRef.current.value = "";
    setQuery(queryValue);
  }

  return (
    <>
      <div
        style={{ padding: "30px", display: "flex", flexDirection: "column" }}
      >
        <textarea
          ref={searchInputRef}
          style={{
            width: "80%",
            maxWidth: "500px",
            height: "40px",
          }}
        />
        <div style={{ padding: "10px" }}>
          <button
            style={{ width: "100px" }}
            onClick={() => handleSubmitSearch()}
            className="m-2"
            ref={executeButtonRef}
          >
            search
          </button>
        </div>
        <br />
        <br />
        {searchLoading ? <span>loading...</span> : null}
        {searchError ? <span>Error: {searchError.message}</span> : null}
        {items ? <ChildItems item={{ childItems: items }} /> : <></>}
      </div>
    </>
  );
}
