import React from "react";
import ParentItem from "./ParentItem";

export default function ParentItemsView({
  parentItems,
  removeParentFunction,
  publicView,
  onItemClick,
}) {
  function createHtmlForParentItems(parentItems) {
    if (parentItems == null) {
      return <></>;
    }
    return parentItems?.map((parentItem) => (
      <ParentItem
        item={parentItem}
        removeParentFunction={removeParentFunction}
        publicView={publicView}
        onItemClick={onItemClick}
      />
    ));
  }
  return (
    <table style={{ marginLeft: "10px" }}>
      <tbody>{createHtmlForParentItems(parentItems)}</tbody>
    </table>
  );
}
