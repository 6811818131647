// components/DebugModal.js
import React from "react";

const DebugModal = ({
  isOpen,
  onClose,
  location,
  onTour,
  tourId,
  currentLeg,
  currentStep,
  stepDistances,
  closestStep,
  legs,
  tourFullyLoaded,
}) => {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "5px",
          maxWidth: "600px",
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <h2>Debug Information</h2>
        <p>Latitude: {location.lat}</p>
        <p>Longitude: {location.lon}</p>
        <p>On Tour: {onTour ? "Yes" : "No"}</p>
        <p>Tour ID: {tourId || "N/A"}</p>
        <p>Current Leg: {currentLeg !== null ? currentLeg : "N/A"}</p>
        <p>Current Step: {currentStep !== null ? currentStep : "N/A"}</p>
        <p>All Audio Files Loaded: {tourFullyLoaded ? "Yes" : "No"}</p>
        <h3>Closest Step:</h3>
        {closestStep ? (
          <p>
            Leg {closestStep.legIndex}, Step {closestStep.stepIndex}:{" "}
            {closestStep.distance.toFixed(2)} meters
          </p>
        ) : (
          <p>No closest step available</p>
        )}
        <h3>Step Distances, Audio URLs, and Play Buttons:</h3>
        {stepDistances && stepDistances.length > 0 ? (
          <ul style={{ maxHeight: "200px", overflowY: "auto" }}>
            {stepDistances.map((step, index) => {
              const audioUrl =
                legs &&
                legs[step.legIndex] &&
                legs[step.legIndex].steps[step.stepIndex]
                  ? legs[step.legIndex].steps[step.stepIndex].audioUrl
                  : "N/A";
              return (
                <li key={index}>
                  Leg {step.legIndex}, Step {step.stepIndex}:{" "}
                  {step.distance.toFixed(2)} meters
                  <br />
                  Audio URL: {audioUrl}
                  <br />
                </li>
              );
            })}
          </ul>
        ) : (
          <p>No step distances available</p>
        )}
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default DebugModal;
