import React, { useEffect, useRef, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import StudyScatter from "./components/StudyScatter";

const GET_TODOS = gql`
  query {
    stats
  }
`;

export default function Stats() {
  const { loading, error, data } = useQuery(GET_TODOS);

  function prettifyJson(jsonString) {
    let jsonObject = JSON.parse(jsonString);
    return JSON.stringify(jsonObject, null, 6); // 2 spaces of indentation
  }

  return (
    <>
      <StudyScatter />
      <div
        style={{
          whiteSpace: "pre-line",
          whiteSpace: "pre-wrap",
        }}
        className="m-3"
      >
        {data ? prettifyJson(data["stats"]) : ""}
      </div>
    </>
  );
}
