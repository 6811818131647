import React from "react";

function ItemViewPublicPrivateSwitch({ isPublic, setIsPublicView }) {
  const handleChange = (event) => {
    // Convert the string value back to a boolean
    const value = event.target.value === "true";
    setIsPublicView(value);
  };

  return (
    <select
      value={isPublic.toString()}
      onChange={handleChange}
      className="dropdown"
    >
      <option value="false">private</option>
      <option value="true">public</option>
    </select>
  );
}

export default ItemViewPublicPrivateSwitch;
