import React, { useRef, useEffect, useState, useContext } from "react";
import { useMutation, gql } from "@apollo/client";

import ItemText from "./ItemText";
import ItemControlPanel from "./ItemControlPanel";
import ItemRatingInner from "./ItemRatingInner";
import ItemCreateModal from "./ItemCreateModal";

import ChildItems from "./ChildItems";
import SimpleSnoozePanel from "./SimpleSnoozePanel";
import GlobalContext from "../GlobalContext";
import { tr } from "date-fns/locale";

const UPDATE_ITEM_FIELD = gql`
  mutation UpdateItemField(
    $itemId: String!
    $fieldName: String!
    $newValue: String!
  ) {
    updateItemField(
      itemId: $itemId
      fieldName: $fieldName
      newValue: $newValue
    ) {
      item {
        id
        name
        href
        text
        isPublic
      }
    }
  }
`;

const thresholdForLongAssText = 2000;
const calculateRows = (text) => {
  if (typeof text !== "string") return 1; // default value if text is not a string
  const lineBreaks = (text.match(/\n/g) || []).length;
  return lineBreaks + 6; // +6 for a little extra padding
};

export default function ItemView({
  item,
  loading,
  error,
  navigatePageToItem,
  refetchItem,
  publicView,
  initiallyShowStudyDetails = false,
  purpose, // "graph" or "study" or "news" or "child_item_for_sidebar"
  removeItemById,
  defaultToZenMode,
  dragAndDrop,
}) {
  const [
    updateItemFieldMutation,
    { loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_ITEM_FIELD);

  const { userSettings, setUserSettings } = useContext(GlobalContext);
  const [editMode, setEditMode] = useState(false);
  const [studyMode, setStudyMode] = useState(purpose == "study");
  const [newsMode, setNewsMode] = useState(purpose == "news");
  const [showControlPanel, setShowControlPanel] = useState(false);
  const [showAllTheStuff, setShowAllTheStuff] = useState(!defaultToZenMode);
  const [showItemCreateModal, setShowItemCreateModal] = useState(true);
  const [showItemText, setShowItemText] = useState(true);
  const [showSummary, setShowSummary] = useState(false);

  const [itemTextIsLongAsFuck, setItemTextIsLongAsFuck] = useState(false);

  const [unsavedChangesExist, setUnsavedChangesExist] = useState(false);
  const [nameIsUnsaved, setNameIsUnsaved] = useState(false);
  const [hrefIsUnsaved, setHrefIsUnsaved] = useState(false);
  // TODO: use this~!!!!!!!!!!!!
  const [areaOfRelevanceIsUnsaved, setAreaOfRelevanceIsUnsaved] =
    useState(false);
  const [textIsUnsaved, setTextIsUnsaved] = useState(false);
  const [accessIsUnsaved, setAccessIsUnsaved] = useState(false);
  const [itemText, setItemText] = useState(item?.text || "");
  const [primaryDisplay, setPrimaryDisplay] = useState("");
  const [itemName, setItemName] = useState(item?.name || "");
  const [itemHref, setItemHref] = useState(item?.href || "");
  const [itemAreaOfRelevance, setItemAreaOfRelevance] = useState(
    item?.areaOfRelevance || ""
  );

  const [rowsForTextInput, setRowsForTextInput] = useState(
    calculateRows(itemText)
  );

  useEffect(() => {
    setItemText(item.text || "");
    setItemName(item.name || "");
    setItemHref(item.href || "");
    setItemTextIsLongAsFuck(item.text?.length > thresholdForLongAssText);
    setChildItems(item.childItems || []);
  }, [item]);

  useEffect(() => {
    setShowItemText(!(itemTextIsLongAsFuck && studyMode));
  }, [itemTextIsLongAsFuck]);

  useEffect(() => {
    setRowsForTextInput(calculateRows(itemText));
  }, [itemText]);

  const typingTextTimeoutRef = useRef(null);
  const typingNameTimeoutRef = useRef(null);
  const typingHrefTimeoutRef = useRef(null);
  const typingAreaOfRelevanceTimeoutRef = useRef(null);

  const onItemChangeDelayed = (
    fieldName,
    newValue,
    ref,
    setIsUnsaved,
    setNewValue
  ) => {
    setNewValue(newValue);
    setIsUnsaved(true);
    if (ref.current) {
      clearTimeout(ref.current); // clear the previous timeout if it exists
    }

    ref.current = setTimeout(() => {
      saveItemField(fieldName, newValue, setIsUnsaved);
    }, 2000);
  };

  const saveItemField = (fieldName, newValue, setIsUnsaved) => {
    return updateItemFieldMutation({
      variables: {
        itemId: item.id,
        fieldName: fieldName,
        newValue: newValue,
      },
    })
      .then((response) => {
        setIsUnsaved(false);
      })
      .catch((err) => {
        console.error("Error updating item field:", err);
        // TODO: stop alerting here, and instead catch the error and alert in the component that calls this function. Right now, we do this in the ItemAccessSwitch component and nowhere else.
        alert("Error updating item field:", err);
        throw err; // Propagate the error
      });
  };
  const [childItems, setChildItems] = useState(item.childItems || []);

  const addItemToChildItems = (newItem) => {
    setChildItems((currentChildItems) => [newItem, ...currentChildItems]);
  };

  useEffect(() => {
    if (
      nameIsUnsaved ||
      hrefIsUnsaved ||
      textIsUnsaved ||
      accessIsUnsaved ||
      areaOfRelevanceIsUnsaved
    ) {
      setUnsavedChangesExist(true);
    } else {
      setUnsavedChangesExist(false);
    }
  }, [
    nameIsUnsaved,
    hrefIsUnsaved,
    textIsUnsaved,
    accessIsUnsaved,
    areaOfRelevanceIsUnsaved,
  ]);

  useEffect(() => {
    if (studyMode && item.imageUrl) {
      setPrimaryDisplay(
        <img
          src={item.imageUrl}
          style={{
            width: "300px",
            height: "300px",
            margin: "20px",
          }}
        />
      );
    } else if (studyMode && item.quizQuestion) {
      setPrimaryDisplay(item.quizQuestion);
    } else if (item.name) {
      setPrimaryDisplay(item.name);
    } else if (item?.text?.length < thresholdForLongAssText) {
      setPrimaryDisplay(<ItemText item={item} />);
      setShowItemText(false); // showItemText could rightly be renamed to showItemTextInItsUsualSpot, that's why it's being set to false here, because we're showing the itemText as the primary text.
    } else if (item.text) {
      setPrimaryDisplay(
        `<no item name and text is long as fuck - ${item.text?.length} characters to be exact>`
      );
    } else {
      setPrimaryDisplay("<no name or text>");
    }
  }, [item, studyMode]);

  return (
    <div style={{ marginLeft: "2px" }}>
      <div className="flex-container">
        {editMode && !publicView ? (
          <textarea
            className="document-editor"
            value={itemName}
            rows={1}
            placeholder="name goes here"
            onChange={(e) => {
              onItemChangeDelayed(
                "name",
                e.target.value,
                typingNameTimeoutRef,
                setNameIsUnsaved,
                setItemName
              );
            }}
          ></textarea>
        ) : item.id ? (
          <>
            <div
              style={{
                width: "1px",
                height: "10px",
              }}
              className={`${
                item.childItems?.length > 0 ? "item-with-kids" : ""
              }`}
            ></div>
            <div
              className={` ${item.type == "TAG" ? "tag-item" : ""}`}
              onClick={() => {
                if (!(purpose == "child_item_for_sidebar")) {
                  setShowAllTheStuff((prev) => !prev);
                }
              }}
              style={{
                marginLeft: "7px",
                padding: "2px",
                paddingLeft: "6px",
                paddingRight: "6px",
              }}
            >
              {primaryDisplay}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      {/* <div className="m-2">ass id: {item.openaiAssistantId}</div>
      <div className="m-2">file id: {item.openaiFileId}</div> */}
      {showAllTheStuff && (
        <div
          style={{
            marginLeft: "20px",
          }}
        >
          <div>
            {item.imageUrl && !studyMode && (
              // if imageUrl and studyMode, the image will be displayed as the primaryDisplay, so don't show it here
              <img
                src={item.imageUrl}
                style={{
                  width: "300px",
                  height: "300px",
                  margin: "20px",
                }}
              />
            )}
            {item.id && (
              <div className="flex-container item-view-button-area">
                <>
                  <div
                    className="circle-button-outer"
                    onClick={() => setShowControlPanel((prev) => !prev)}
                  >
                    <div
                      className={"circle-button-inner"}
                      style={{
                        backgroundColor: "#FDFFB6", // classic bonsai canary yellow
                        // backgroundColor: "#fcff9c", // gross new yellow that has more contrast with the other colors but is ultimately just too gross
                      }}
                    ></div>
                  </div>
                  {!publicView ? (
                    <>
                      {userSettings?.userId == item.user?.id && (
                        <div
                          className="circle-button-outer"
                          onClick={() => setEditMode((prev) => !prev)}
                        >
                          <div
                            className={"circle-button-inner"}
                            style={{
                              // backgroundColor: "#FFD6A5",
                              backgroundColor: "#ffd199", // creamsicle orange
                            }}
                          ></div>
                        </div>
                      )}
                      {item.isPublic == "true" && (
                        <>
                          {/* only wrapping in this div to get the spacing between buttons to be the same as the others */}
                          <div className="circle-button-outer">
                            <div
                              className={"circle-button-inner is-public"}
                            ></div>
                          </div>
                        </>
                      )}
                      {(updateLoading || unsavedChangesExist) && (
                        <>
                          <div className="circle-button-outer">
                            <div
                              className={`circle-button-inner ${
                                updateLoading
                                  ? "loading"
                                  : unsavedChangesExist
                                  ? "unsaved"
                                  : "saved" // this should never appear but leaving it here in case something goes wrong so the user doesn't think there are pending changes indefinitely
                              }`}
                            ></div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              </div>
            )}
            {showControlPanel ? (
              <div>
                <ItemControlPanel
                  item={item}
                  refetch={() => {
                    console.log("refetcchhhh");
                  }}
                  saveItemField={saveItemField}
                  setAccessIsUnsaved={setAccessIsUnsaved}
                  publicView={publicView}
                  onItemClick={navigatePageToItem}
                  studyMode={studyMode}
                  userSettings={userSettings}
                  initiallyShowStudyDetails={initiallyShowStudyDetails}
                />
              </div>
            ) : (
              <></>
            )}
            <div>
              {item.summary && (
                <button
                  onClick={() => setShowSummary((prev) => !prev)}
                  className="item-view-button"
                >
                  {showSummary ? "hide summary" : "show summary"}
                </button>
              )}
              {itemTextIsLongAsFuck && (
                <button
                  onClick={() => setShowItemText((prev) => !prev)}
                  className="item-view-button"
                >
                  {showItemText
                    ? "hide long as fuck text"
                    : "show long as fuck text"}
                </button>
              )}
            </div>
            {/* i don't use this anymore so I'm going to shut it off */}
            {/* {studyMode && userSettings.showFineGrainedRatingsInStudyView ? (
              <div
                style={{
                  padding: "30px",
                }}
              >
                <ItemRatingInner
                  rating_type_for_display={"importance"}
                  rating_type={"importance"}
                  item_id={item.id}
                  existing_rating={item.importance}
                />
                <ItemRatingInner
                  rating_type_for_display={"recall"}
                  rating_type={"recall"}
                  item_id={item.id}
                  existing_rating={null}
                  updateRatingCallback={() => null}
                />
              </div>
            ) : (
              <></>
            )} */}
            {editMode ? (
              <textarea
                className="document-editor"
                value={itemHref}
                rows={1}
                placeholder="url goes here"
                onChange={(e) => {
                  onItemChangeDelayed(
                    "href",
                    e.target.value,
                    typingHrefTimeoutRef,
                    setHrefIsUnsaved,
                    setItemHref
                  );
                }}
              ></textarea>
            ) : item.href ? (
              <div
                style={{
                  margin: "15px",
                  marginLeft: "8px",
                  marginBottom: "30px",
                }}
              >
                <a href={item.href}>{item.href}</a>
              </div>
            ) : (
              <></>
            )}
            {item.lat ? (
              <div
                style={{
                  margin: "15px",
                  marginLeft: "8px",
                  marginBottom: "30px",
                }}
              >
                lat: {item.lat}
              </div>
            ) : (
              <></>
            )}
            {item.lon ? (
              <div
                style={{
                  margin: "15px",
                  marginLeft: "8px",
                  marginBottom: "30px",
                }}
              >
                lon: {item.lon}
              </div>
            ) : (
              <></>
            )}
            {editMode ? (
              <textarea
                className="document-editor"
                value={itemAreaOfRelevance}
                rows={1}
                placeholder="area of relevance goes here"
                onChange={(e) => {
                  onItemChangeDelayed(
                    "areaOfRelevance",
                    e.target.value,
                    typingAreaOfRelevanceTimeoutRef,
                    setAreaOfRelevanceIsUnsaved,
                    setItemAreaOfRelevance
                  );
                }}
              ></textarea>
            ) : item.areaOfRelevance ? (
              <div
                style={{
                  margin: "15px",
                  marginLeft: "8px",
                  marginBottom: "30px",
                }}
              >
                {item.areaOfRelevance}
              </div>
            ) : (
              <></>
            )}
          </div>
          {item?.summary && showSummary && (
            <div>
              <>{item.summary}</>
            </div>
          )}
          {showSummary && showItemText && (
            <div className="summary-divider">_____________________________</div>
          )}

          {item && editMode && !publicView && (
            <div className="m-2">
              <textarea
                className="document-editor"
                value={itemText}
                rows={rowsForTextInput}
                placeholder="type stuff here"
                onChange={(e) => {
                  onItemChangeDelayed(
                    "text",
                    e.target.value,
                    typingTextTimeoutRef,
                    setTextIsUnsaved,
                    setItemText
                  );
                }}
              ></textarea>
            </div>
          )}

          {item && showItemText && !editMode && (
            <ItemText item={item} onHighlightCreation={() => {}} />
          )}

          {/* <div
            className="m-3"
            onClick={() => setShowItemCreateModal((prev) => !prev)}
          >
            +
          </div> */}
          {showItemCreateModal ? (
            <div
              style={{
                marginLeft: "10px",
                marginBottom: "20px",
              }}
            >
              <ItemCreateModal
                extantItem={item}
                onItemCreate={(item) => addItemToChildItems(item)}
                saveItemField={saveItemField}
                setAccessIsUnsaved={setAccessIsUnsaved}
                publicView={publicView}
                onItemClick={navigatePageToItem}
                studyMode={studyMode}
                userSettings={userSettings}
                initiallyShowStudyDetails={initiallyShowStudyDetails}
              />
            </div>
          ) : (
            <></>
          )}
          {item?.childItems?.length === 0 && publicView ? (
            <div>no public content for this topic</div>
          ) : (
            <ChildItems
              item={{ ...item, childItems: childItems }}
              navigatePageToItem={navigatePageToItem}
              loading={loading}
              dragAndDrop={dragAndDrop}
            />
          )}
        </div>
      )}
      {(studyMode || newsMode) && (
        <div
          style={{
            marginLeft: "20px",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <SimpleSnoozePanel item={item} removeItemById={removeItemById} />
          {userSettings && !userSettings.hideRatingsInMostPlaces && (
            <ItemRatingInner
              rating_type_for_display={""}
              rating_type={"importance"}
              item_id={item.id}
              existing_rating={item.importance}
              squareButtons={true}
            />
          )}
        </div>
      )}
    </div>
  );
}
