import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { LOGIN, CREATE_FIRST_TASK_AND_ACCOUNT } from "../mutations"; // Update the imports here

const Onboarding = ({ isLoggedIn }) => {
  return (
    <div
      style={{
        padding: "2rem",
      }}
    >
      <h3>welcome to talkimapi</h3>
      <br></br>
      <p>your AI tour guide</p>
      <p>
        already have an account?&nbsp;&nbsp;
        <Link to="/login">log in</Link>
      </p>
      <p>if not, talk to the webmaster</p>
    </div>
  );
};

export default Onboarding;
