import React, { useState } from "react";

function ItemAccessSwitch({ item, saveItemField, setAccessIsUnsaved }) {
  const [currentAccessValue, setCurrentAccessValue] = useState(item.access);
  const saveNewAccessValue = (newAccessValue) => {
    setAccessIsUnsaved(true);
    saveItemField("access", newAccessValue, setAccessIsUnsaved)
      .then((response) => {
        // no error so update the value
        setCurrentAccessValue(newAccessValue);
      })
      .catch((err) => {
        console.error("Error while saving:", err);
        alert("Error updating item field. Please try again."); // Alert the user
      });
  };
  return (
    <div className="visibility-switch">
      <label className={currentAccessValue === "public" ? "active" : ""}>
        <input
          type="radio"
          value="public"
          checked={currentAccessValue === "public"}
          onChange={(e) => {
            saveNewAccessValue("public");
          }}
        />
        public
      </label>
      <label className={currentAccessValue === "private" ? "active" : ""}>
        <input
          type="radio"
          value="private"
          checked={currentAccessValue === "private"}
          onChange={(e) => {
            saveNewAccessValue("private");
          }}
        />
        private
      </label>
      <label className={currentAccessValue === "inherit" ? "active" : ""}>
        <input
          type="radio"
          value="inherit"
          checked={currentAccessValue === "inherit"}
          onChange={(e) => {
            saveNewAccessValue("inherit");
          }}
        />
        inherit
      </label>
    </div>
  );
}

export default ItemAccessSwitch;
