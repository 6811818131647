import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";

const MESSAGES = gql`
  query messages {
    messages {
      body
      user {
        username
      }
      phone
      email
      type
      direction
      result
      createdAt
    }
  }
`;

export default function Messages() {

  const [messages, setMessages] = useState([]);
  
  const {
    loading,
    data: messagesData,
    error: messagesError
  } = useQuery(MESSAGES, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      setMessages(data.messages);
    },
    onError(err) {
      alert(`Error fetching messages: ${err.message}`);
    },
  });

  useEffect(() => {
    if (messagesError) {
      alert(`Error loading messages: ${messagesError.message}`);
    }
  }, [messagesError]);

  if (loading) return <p>Loading...</p>;
  if (messagesError) return <p>Error: {messagesError.message}</p>;

  return (
    <div>
      <table border="1">
        <thead>
          <tr>
            <th>body</th>
            <th>user</th>
            <th>phone</th>
            <th>email</th>
            <th>type</th>
            <th>direction</th>
            {/* <th>Result</th> */}
            <th>created_at</th>

          </tr>
        </thead>
        <tbody>
          {messages.map((message, index) => (
            <tr key={index}>
              <td>{message.body}</td>
              <td>{message.user?.username}</td>
              <td>{message.phone}</td>
              <td>{message.email}</td>
              <td>{message.type}</td>
              <td>{message.direction}</td>
              {/* <td>{message.result}</td> */}
              <td>{message.createdAt}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

