import React, { useEffect, useState } from "react";
import {
  VictoryScatter,
  VictoryChart,
  VictoryTooltip,
  VictoryAxis,
  VictoryLegend,
} from "victory";
import { scaleQuantize } from "d3-scale";
import { interpolate } from "d3-interpolate";

import { useQuery, gql } from "@apollo/client";

const FETCH_BONSAI_ITEMS = gql`
  query FetchBonsaiItems {
    bonsaiItemsWithStats {
      overdueness
      snoozeTime
      importance
      name
      text
    }
  }
`;

const interpolators = [
  interpolate("#f5425d", "#75f542"), // Green to Yellow
  interpolate("#f5425d", "#428df5"), // Yellow to Blue
  interpolate("#428df5", "#c242f5"), // Blue to Purple
];

const colorScale = scaleQuantize()
  .domain([0, 5])
  .range(
    [0, 1, 2, 3, 4, 5].map((t) => {
      const interpolator = interpolators[Math.floor(t / 2)];
      return interpolator((t % 2) * 0.5 + 0.5); // Maps t to the range [0.5, 1] for each interpolator
    })
  );

function StudyScatter() {
  const { data, loading, error } = useQuery(FETCH_BONSAI_ITEMS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const bonsaiItemsWithStats = data?.bonsaiItemsWithStats || [];

  const chartData = bonsaiItemsWithStats.map((item) => ({
    x: item.overdueness,
    y: item.snoozeTime,
    fill: colorScale(item.importance),
    size: 1,
    importance: item.importance,
    name: item.name,
    text: item.text,
  }));
  const legendData = Array.from({ length: 6 }).map((_, index) => ({
    name: `${index}`,
    symbol: { fill: colorScale(index) },
  }));

  return (
    <VictoryChart domainPadding={15}>
      <VictoryLegend
        x={125}
        y={20}
        orientation="horizontal"
        gutter={20}
        data={legendData}
        itemsPerRow={6}
        style={{ labels: { fontSize: 4 } }}
        symbol={{ size: 1 }}  // Adjust the size as required
      />
      <VictoryAxis
        label="overdueness"
        style={{
          tickLabels: { fontSize: 4 }, // Adjust font size of axis ticks
          axisLabel: { fontSize: 4 }, // Adjust font size of axis label
        }}
      />
      <VictoryAxis
        dependentAxis
        // scale="log" // Apply log scale to the y-axis
        label="snooze time (days, log scale, is this updating)"
        style={{
          tickLabels: { fontSize: 4 }, // Adjust font size of axis ticks
          axisLabel: { fontSize: 4 }, // Adjust font size of axis label
        }}
      />
      <VictoryScatter
        data={chartData}
        labels={({ datum }) =>
          `importance: ${datum.importance}\n${
            false ? "" : "name: " + datum.name
          }\n${false ? "" : "text: " + datum.text}`
        }
        labelComponent={
          <VictoryTooltip
            style={[
              { fontSize: 4 }, // Adjust font size for the tooltip
            ]}
            flyoutStyle={{ fill: "transparent", stroke: "transparent" }} // Remove word bubble appearance
          />
        }
        style={{
          data: { fill: ({ datum }) => datum.fill },
        }}
        size={({ datum }) => datum.size}
      />
    </VictoryChart>
  );
}

export default StudyScatter;
