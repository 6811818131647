import React, { useState, useEffect } from "react";
import { useMutation, gql } from "@apollo/client";
// import userEvent from "@testing-library/user-event"; // no idea what this is supposed to be for

const colorPalette = [
  // probably yes
  "#1D3557",
  "#CAFFBF",
  "#E29578",
  "#E63946",
  "#A0C4FF",
  "#457B9D",
  "#9BF6FF",

  // // uncertain
  // "#FFADAD",
  // "#FFD6A5",
  // "#FDFFB6",
  // "#BDB2FF",
  // "#FFC6FF",
  // //ngozi's colors
  // "#F1FAEE",
  // "#A8DADC",
  // // my fav green that ngozi doesn't like

  "#C3E739",
];
function DraggableItem({
  initialItem,
  index,
  provided,
  onBuildTaskListClick,
  onExecuteViaBambooClick,
  onExecuteHoweverClick,
  onSetParentClick,
  onDelete,
}) {
  const secondaryAccentColor =
    colorPalette[Math.floor(Math.random() * colorPalette.length)];
  const [item, setItem] = useState(initialItem);
  const [isExecutingTaskListGeneration, setIsExecutingTaskListGeneration] =
    useState(false);
  const [isExecutingViaBamboo, setIsExecutingViaBamboo] = useState(false);
  const [isExecutingHowever, setIsExecutingHowever] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedDescription, setEditedDescription] = useState(item.description);
  const [editedName, setEditedName] = useState(item.name);
  const [taskRabbitClicked, setTaskRabbitClicked] = useState(false);

  useEffect(() => {
    console.log("item mounting", item);
  }, []);

  const UPDATE_ITEM_NAME_OR_DESCRIPTION_MUTATION = gql`
    mutation UpdateItemNameOrDescription(
      $itemId: String!
      $description: String!
      $name: String
    ) {
      updateItemNameOrDescription(
        itemId: $itemId
        description: $description
        name: $name
      ) {
        item {
          id
          description
          name
          public
          additionalDescription
          status
          type
          include
          parentItem {
            id
          }
          childItems {
            id
          }
          assignableUsers {
            id
            username
          }
          assignedTo {
            id
            username
          }
        }
      }
    }
  `;

  const [UpdateItemNameOrDescription] = useMutation(
    UPDATE_ITEM_NAME_OR_DESCRIPTION_MUTATION
  );

  const handleEditDescriptionOrName = async () => {
    if (isEditing) {
      try {
        const { data } = await UpdateItemNameOrDescription({
          variables: {
            itemId: item.id,
            description: editedDescription,
            name: editedName,
          },
        });

        if (data) {
          const updatedItem = data.updateItemNameOrDescription.item;
          setItem(updatedItem);
        } else {
          console.error("Error updating task description");
        }
      } catch (error) {
        console.error("Error executing updateTaskDescription mutation:", error);
      }
    } else {
      // Enable editing mode
      setEditedDescription(item.description);
    }

    // Toggle editing mode
    setIsEditing(!isEditing);
  };
  const handleBuildTaskListClick = async () => {
    setIsExecutingTaskListGeneration(true);
    if (onBuildTaskListClick) {
      try {
        await onBuildTaskListClick(item);
      } catch (error) {
        console.error("Error executing task:", error);
      } finally {
        setIsExecutingTaskListGeneration(false);
      }
    }
  };

  const handleExecuteViaBambooClick = async () => {
    setIsExecutingViaBamboo(true);
    if (onExecuteViaBambooClick) {
      try {
        await onExecuteViaBambooClick(item);
      } catch (error) {
        console.error("Error executing task via bamboo:", error);
      } finally {
        setIsExecutingViaBamboo(false);
      }
    }
  };

  const handleExecuteHoweverClick = async () => {
    setIsExecutingHowever(true);
    if (onExecuteViaBambooClick) {
      try {
        await onExecuteHoweverClick(item);
      } catch (error) {
        console.error("Error executing task via bamboo:", error);
      } finally {
        setIsExecutingHowever(false);
      }
    }
  };

  const handleTaskRabbit = () => {
    setTaskRabbitClicked(true);
  };

  const ASSIGN_TASK_MUTATION = gql`
    mutation AssignTask($taskId: String!, $userId: String!) {
      assignTask(taskId: $taskId, userId: $userId) {
        success
        task {
          id
          type
          description
          additionalDescription
          public
          status
          include
          parentItem {
            id
          }
          childItems {
            id
          }
          assignableUsers {
            id
            username
          }
          assignedTo {
            id
            username
          }
        }
      }
    }
  `;
  const [assignTask] = useMutation(ASSIGN_TASK_MUTATION);
  const handleAssign = async (userId) => {
    if (userId) {
      try {
        const { data } = await assignTask({
          variables: {
            taskId: item.id,
            userId: userId,
          },
        });

        if (data.assignTask.success) {
          setItem(data.assignTask.task);
        } else {
          console.error("Error assigning task:", data.assignTask.message);
        }
      } catch (error) {
        console.error("Error executing assignTask mutation:", error);
      }
    }
  };

  const CHANGE_TASK_STATUS_MUTATION = gql`
    mutation ChangeTaskStatus($taskId: String!, $newStatus: String!) {
      changeTaskStatus(taskId: $taskId, newStatus: $newStatus) {
        success
        task {
          id
          description
          additionalDescription
          status
          public
          type
          include
          parentItem {
            id
          }
          childItems {
            id
          }
          assignableUsers {
            id
            username
          }
          assignedTo {
            id
            username
          }
        }
      }
    }
  `;
  const [changeTaskStatus] = useMutation(CHANGE_TASK_STATUS_MUTATION);
  const handleChangeTaskStatus = async (newStatus) => {
    try {
      const { data } = await changeTaskStatus({
        variables: {
          taskId: item.id,
          newStatus: newStatus,
        },
      });

      if (data.changeTaskStatus.success) {
        setItem(data.changeTaskStatus.task);
      } else {
        console.error(
          "Error changing task status:",
          data.changeTaskStatus.message
        );
      }
    } catch (error) {
      console.error("Error executing changeTaskStatus mutation:", error);
    }
  };

  // const generateBackgroundColor = (index) => {
  //   // const hue = (index * 200) % 360;
  //   const hue = 200;
  //   return `hsl(${hue}, 40%, 95%)`;
  // };
  const buttonStyle = {
    background: "white",
    border: "1px solid #ccc",
    borderRadius: "4px",
    marginRight: "0.5rem",
    padding: "0.25rem 0.5rem",
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  // ...

  const triangleStyle = {
    display: "inline-block",
    width: "0",
    height: "0",
    marginLeft: "0.5rem",
    marginRight: "1rem", // Added marginRight property to create space
    borderStyle: "solid",
    // borderWidth: "5px 0 5px 8.7px", // original size
    borderWidth: "7px 0 7px 12.18px",
    borderColor: "transparent transparent transparent black",
    cursor: "pointer",
    transition: "transform 0.3s",
    transform: isExpanded ? "rotate(90deg)" : "",
  };

  const [isAdditionalDescriptionChanged, setIsAdditionalDescriptionChanged] =
    useState(false);
  const [additionalDescriptionTimeout, setAdditionalDescriptionTimeout] =
    useState(null);

  const handleAdditionalDescriptionChange = (e) => {
    clearTimeout(additionalDescriptionTimeout);
    setIsAdditionalDescriptionChanged(true);
    setItem({ ...item, additionalDescription: e.target.value });
    setAdditionalDescriptionTimeout(
      setTimeout(() => {
        saveAdditionalDescription();
      }, 1000)
    );
  };

  useEffect(() => {
    return () => {
      clearTimeout(additionalDescriptionTimeout);
    };
  }, []);

  const UPDATE_ADDITIONAL_DESCRIPTION_MUTATION = gql`
    mutation UpdateAdditionalDescription(
      $taskId: String!
      $additionalDescription: String!
    ) {
      updateAdditionalDescription(
        taskId: $taskId
        additionalDescription: $additionalDescription
      ) {
        item {
          id
          description
          additionalDescription
          status
          public
          type
          include
          parentItem {
            id
          }
          childItems {
            id
          }
          assignableUsers {
            id
            username
          }
          assignedTo {
            id
            username
          }
        }
      }
    }
  `;
  const [updateAdditionalDescription] = useMutation(
    UPDATE_ADDITIONAL_DESCRIPTION_MUTATION
  );
  const saveAdditionalDescription = async () => {
    try {
      const { data } = await updateAdditionalDescription({
        variables: {
          taskId: item.id,
          additionalDescription: item.additionalDescription,
        },
      });

      if (data) {
        const updatedTask = data.updateAdditionalDescription.task;
        setItem({
          ...item,
          additionalDescription: updatedTask.additionalDescription,
        });
      } else {
        console.error("Error updating additional description");
      }
    } catch (error) {
      console.error(
        "Error executing updateAdditionalDescription mutation:",
        error
      );
    }

    setIsAdditionalDescriptionChanged(false);
  };

  const TOGGLE_INCLUDE_ITEM_MUTATION = gql`
    mutation ToggleIncludeItem($itemId: String!) {
      toggleIncludeItem(itemId: $itemId) {
        success
        item {
          id
          description
          additionalDescription
          status
          public
          type
          include
          parentItem {
            id
          }
          childItems {
            id
          }
          assignableUsers {
            id
            username
          }
          assignedTo {
            id
            username
          }
        }
      }
    }
  `;

  const [toggleIncludeItemMutation] = useMutation(TOGGLE_INCLUDE_ITEM_MUTATION);

  const toggleIncludeItem = async () => {
    try {
      const { data } = await toggleIncludeItemMutation({
        variables: {
          itemId: item.id,
        },
      });

      if (data && data.toggleIncludeItem.success) {
        if (data && data.toggleIncludeItem.item) {
          console.log("Item include status updated successfully");
          setItem(data.toggleIncludeItem.item);
        } else {
          console.error("Error setting new include status on page");
        }
      } else {
        console.error("Error updating item include status");
      }
    } catch (error) {
      console.error("Error executing toggleIncludeItem mutation:", error);
    }
  };
  const TOGGLE_PUBLIC_PRIVATE_MUTATION = gql`
    mutation TogglePublicPrivate($itemId: String!) {
      togglePublicPrivate(itemId: $itemId) {
        success
        item {
          id
          description
          additionalDescription
          status
          public
          type
          include
          parentItem {
            id
          }
          childItems {
            id
          }
          assignableUsers {
            id
            username
          }
          assignedTo {
            id
            username
          }
        }
      }
    }
  `;

  const [togglePublicPrivateMutation] = useMutation(
    TOGGLE_PUBLIC_PRIVATE_MUTATION
  );

  const togglePublicPrivate = async () => {
    try {
      const { data } = await togglePublicPrivateMutation({
        variables: {
          itemId: item.id,
        },
      });

      if (data && data.togglePublicPrivate.success) {
        if (data && data.togglePublicPrivate.item) {
          console.log("Item public flag updated successfully");
          setItem(data.togglePublicPrivate.item);
        } else {
          console.error("Error setting new public private flag on page");
        }
      } else {
        console.error("Error updating public private");
      }
    } catch (error) {
      console.error("Error executing togglePublicPrivate mutation:", error);
    }
  };

  return (
    <div
      onClick={() => onSetParentClick(item)}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        userSelect: "none",
        padding: "0.5rem 1rem",
        margin: "0 0 0.5rem 0",
        // background: "white",
        border: "1px solid #ccc",
        // background: generateBackgroundColor(index),
        // background: "#F4FCE3",
        background: "#f6f6f6",
        ...provided.draggableProps.style,
      }}
    >
      {/* <div
        onClick={(e) => {
          e.stopPropagation();
          toggleExpanded();
        }}
        style={triangleStyle}
      ></div> */}

      {isEditing ? (
        <input
          placeholder="name"
          value={editedName}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => setEditedName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleEditDescriptionOrName();
            }
          }}
          style={{ maxWidth: "10%" }}
        />
      ) : (
        <span
          style={{
            maxWidth: "500px",
            marginLeft: "10px",
          }}
        >
          {item["name"]}
        </span>
      )}
      {isEditing ? (
        <input
          value={editedDescription}
          placeholder="description"
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => setEditedDescription(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleEditDescriptionOrName();
            }
          }}
          style={{ minWidth: "50%" }}
        />
      ) : (
        <span
          style={{
            maxWidth: "500px",
            marginLeft: "10px",
          }}
        >
          {item["description"]}
        </span>
      )}
      <div style={{ marginLeft: "auto" }}>
        {item["childItems"].length > 0 && (
          <span
            style={{
              marginRight: "0.5rem",
              padding: "0.25rem",
              display: "inline-block",
            }}
          >
            {item["childItems"].length}
          </span>
        )}
        {item && item["type"] && item["type"].toLowerCase() == "task" ? (
          <>
            <button
              onClick={(e) => {
                e.stopPropagation();
                togglePublicPrivate();
              }}
              style={{
                ...buttonStyle,
                marginRight: "0.5rem",
                background: item.public ? "#3C474B" : "white",
                color: item.public ? "white" : "black",
              }}
            >
              {item.public ? "public" : "private"}
            </button>
            <select
              value={item["status"]}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                e.stopPropagation();
                handleChangeTaskStatus(e.target.value);
              }}
              style={{
                marginRight: "0.5rem",
                padding: "0.25rem",
                background: "white",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            >
              <option value="none" disabled>
                status
              </option>
              {[
                {
                  id: "0",
                  value: "new",
                },
                {
                  id: "1",
                  value: "in-progress",
                },
                {
                  id: "2",
                  value: "complete",
                },
              ].map((statusOption) => (
                <option key={statusOption.id} value={statusOption.value}>
                  {statusOption.value}
                </option>
              ))}
            </select>
            <select
              value={item.assignedTo ? item.assignedTo.id : 0}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                e.stopPropagation();
                handleAssign(e.target.value);
              }}
              style={{
                marginRight: "0.5rem",
                padding: "0.25rem",
                background: "white",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "100px",
              }}
            >
              <option value="" disabled>
                assign to
              </option>
              {item["assignableUsers"] &&
                [
                  {
                    id: "0",
                    username: "no one",
                  },
                  ...item["assignableUsers"],
                  {
                    id: "1",
                    username: "bamboo",
                  },
                ].map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.username}
                  </option>
                ))}
            </select>
            {/* <button
              onClick={(e) => {
                e.stopPropagation();
                handleTaskRabbit();
              }}
              style={{
                ...buttonStyle,
                background: taskRabbitClicked ? "lightgray" : "white",
                color: taskRabbitClicked ? "rgba(0, 0, 0, 0.5)" : "",
                marginRight: "0.5rem",
              }}
            >
              {taskRabbitClicked
                ? "sent to task rabbit"
                : "send to task rabbit"}
            </button> */}
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleExecuteHoweverClick();
              }}
              style={{
                ...buttonStyle,
                background: isExecutingHowever ? "lightcoral" : "white",
                marginRight: "0.5rem",
              }}
              disabled={isExecutingHowever}
            >
              {isExecutingHowever
                ? "cookin that shit up..."
                : "execute however"}
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleExecuteViaBambooClick();
              }}
              style={{
                ...buttonStyle,
                background: isExecutingViaBamboo ? "lightcoral" : "white",
                marginRight: "0.5rem",
              }}
            >
              {isExecutingViaBamboo
                ? "cookin that shit up..."
                : "execute by producing a doc"}
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleBuildTaskListClick();
              }}
              style={{
                ...buttonStyle,
                background: isExecutingTaskListGeneration
                  ? "lightcoral"
                  : "white",
                marginRight: "0.5rem",
              }}
              disabled={isExecutingTaskListGeneration}
            >
              {isExecutingTaskListGeneration
                ? "cookin that shit up..."
                : "create subtasks"}
            </button>
          </>
        ) : (
          <>
            <button
              onClick={(e) => {
                e.stopPropagation();
                toggleIncludeItem();
              }}
              style={{
                ...buttonStyle,
                background: item.include ? "#3C474B" : "white",
                color: item.include ? "white" : "black",
                // background: item.include ? secondaryAccentColor : "white",
                // color: item.include ? "white" : secondaryAccentColor,
                marginRight: "0.5rem",
              }}
            >
              {/* If the item is current set to include=True, then we will offer the user the chance to exclude it. That's why this seems backwards a little. */}
              {item.include ? "included" : "excluded"}
            </button>
          </>
        )}
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleEditDescriptionOrName();
          }}
          style={{
            ...buttonStyle,
            background: isEditing ? "lightseagreen" : "white",
            marginRight: "0.5rem",
          }}
        >
          {isEditing ? "save" : "edit"}
        </button>
        <button
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
          style={{
            ...buttonStyle,
            marginRight: "0.5rem",
          }}
        >
          x
        </button>
      </div>
      {isExpanded && (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{ marginTop: "1rem", width: "100%" }}
        >
          <h4>additional description:</h4>
          <textarea
            value={item.additionalDescription}
            onChange={handleAdditionalDescriptionChange}
            style={{
              width: "100%",
              minHeight: "100px",
              resize: "vertical",
              border: "1px solid #ccc",
              borderRadius: "4px",
              boxShadow: isAdditionalDescriptionChanged
                ? "0 0 5px rgba(255, 0, 0, 0.3)"
                : "",
              backgroundColor: isAdditionalDescriptionChanged
                ? "rgba(240, 248, 255, 0.9)"
                : "",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default DraggableItem;
