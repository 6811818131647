import React, { useRef, useEffect } from 'react';
import * as utils from "../utils"; // Adjust the path based on your file structure

function ItemViewTypeSwitch({ item, currentViewType, onClick }) {
  const selectRef = useRef(null);

  useEffect(() => {
    utils.adjustWidth(selectRef);
  }, [currentViewType]); // Run when currentViewType changes

  const handleChange = (e) => {
    onClick(e.target.value);
    utils.adjustWidth(selectRef);
  };

  return (
    <div>
      <select
        ref={selectRef}
        className="dropdown"
        value={currentViewType}
        onChange={handleChange}
      >
        {/* <option value="top">similarity/projection of embeddings</option> */}
        <option value="top">top</option>
        <option value="chronological">chronological</option>
        <option value="study">study</option>
        <option value="arbitrary">ordered</option>
        <option value="shuffle">shuffle</option>
        <option value="hot">hot</option>
        <option value="top">neighbors</option>
        {/* <option value="search">search</option> */}
      </select>
    </div>
  );
}

export default ItemViewTypeSwitch;
