import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { LOGIN, TRIGGER_MAGIC_LINK_EMAIL } from "./mutations";

export default function Login({ onLogin }) {
  const [loginMutation, { data, loading, error }] = useMutation(LOGIN);
  const [
    triggerMagicLinkEmailMutation,
    { data: tmleData, loading: tmleLoading, error: tmleError },
  ] = useMutation(TRIGGER_MAGIC_LINK_EMAIL);
  const [loginButtonColor, setLoginButtonColor] = useState("#5ca88f");
  const [loginStatus, setLoginStatus] = useState(null);

  useEffect(() => {
    if (typeof data !== "undefined") {
      let token = data.tokenAuth.token;
      if (data.tokenAuth.success) {
        localStorage.setItem("token", token);
        setLoginButtonColor("#B439E7");
        setLoginStatus("success");
        onLogin();
      } else {
        setLoginStatus("failed");
      }
    }
  }, [data]);

  const usernameRef = useRef();
  const passwordRef = useRef();
  const emailRef = useRef();

  function handleLoginClick(e) {
    let username = usernameRef.current.value;
    let password = passwordRef.current.value;
    if (username == "" || password == "") {
      alert("put some stuff in those boxes");
      return;
    }
    loginMutation({ variables: { username: username, password: password } });
    setLoginButtonColor("#4d4dff");
  }

  function handleLoginViaMagicLinkClick(e) {
    let email = emailRef.current.value;
    if (email == "") {
      alert("we need your email");
      return;
    }
    triggerMagicLinkEmailMutation({ variables: { email: email } });
    setLoginButtonColor("#4d4dff");
  }

  useEffect(() => {
    function handleKeyUp(e) {
      let key = e.key;
      if (key === "Enter") {
        handleLoginClick();
      }
    }
    window.addEventListener("keyup", handleKeyUp);
  }, []);

  return (
    <div className="m-5">
      <div>
        <input
          id="username-input"
          ref={usernameRef}
          type="text"
          style={{ marginRight: "1rem" }}
          placeholder="username"
          className="w-full p-2"
        />
        <input
          id="password-input"
          ref={passwordRef}
          type="password"
          style={{ marginRight: "1rem" }}
          placeholder="password"
          className="w-full p-2"
        />
        <button
          className="login-button"
          style={{ backgroundColor: loginButtonColor }}
          onClick={handleLoginClick}
        >
          {loading ? "hang on.." : "log in"}
        </button>
      </div>
      {loginStatus === "success" && (
        <div style={{ marginTop: "1rem" }}>k you're all set</div>
      )}
      {loginStatus === "failed" && (
        <div style={{ marginTop: "1rem" }}>
          well shit. talk to jon because something is broke.
        </div>
      )}
      <div className="text-3xl font-bold my-4 ">- OR -</div>
      <div>
        <input
          id="email-input"
          ref={emailRef}
          type="email"
          style={{ marginRight: "1rem" }}
          placeholder="email"
          className="w-full p-2"
        />
        <button
          className="login-via-magic-link-button"
          style={{ backgroundColor: loginButtonColor }}
          onClick={handleLoginViaMagicLinkClick}
        >
          {loading ? "hang on.." : "receive log-in link"}
        </button>
      </div>
    </div>
  );
}
