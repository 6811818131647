import React from "react";
import { Link } from "react-router-dom";
import UserFlagToggle from "./components/UserFlagToggle";
import Onboarding from "./bamboo-components/Onboarding";

export default function Other({
  isLoggedIn,
  handleLogoutClick,
  backgroundColor,
  userSettings,
}) {
  if (!isLoggedIn) return <Onboarding />;
  return (
    <>
      <div className="m-3">
        <Link to="/about">about</Link>
      </div>
      <div className="m-3">
        <Link to="/upload">upload</Link>
      </div>
      <div className="m-3">
        <Link to="/stats">stats</Link>
      </div>
      <div className="m-3">
        <Link to="/search">search</Link>
      </div>
      <div className="m-3">
        <Link to="/feed">feed</Link>
      </div>
      <div
        onClick={() => {
          alert("GIVE US YOUR MONEY");
        }}
        className="m-3"
      >
        billing/account
      </div>
      <Link to="/admin">
        <div className="m-3">admin</div>
      </Link>
      <div className="m-3">user stuff</div>
      <ul
        style={{
          listStyleType: "none",
        }}
      >
        <li>
          <div className="m-3">
            <UserFlagToggle
              fieldName="dark_mode_enabled"
              initialValue={userSettings.darkModeEnabled}
              displayTextForTrue="light mode"
              displayTextForFalse="dark mode"
              onToggle={() => {
                document.body.classList.toggle("dark-mode"); // Add or remove the dark mode class
              }}
            />
          </div>
        </li>
        <li>
          <div className="m-3">
            <UserFlagToggle
              fieldName="hide_ratings_in_most_places"
              initialValue={userSettings.hideRatingsInMostPlaces}
              displayTextForTrue="show importance ratings everywhere"
              displayTextForFalse="hide importance ratings in most places"
            />
          </div>
        </li>
        <li>
          <div className="m-3">
            <UserFlagToggle
              fieldName="send_daily_news_digest"
              initialValue={userSettings.sendDailyNewsDigest}
              displayTextForTrue="unsubscribe from daily news digest"
              displayTextForFalse="subscribe to daily news digest"
            />
          </div>
        </li>
        <li>
          <div className="m-3">
            <UserFlagToggle
              fieldName="redirect_messages"
              initialValue={userSettings.redirectMessages}
              displayTextForTrue="un-redirect messages"
              displayTextForFalse="redirect messages"
            />
          </div>
        </li>

        {/* i don't use this anymore so I'm going to shut it off */}
        {/* <li>
          <div className="m-3">
            <UserFlagToggle
              fieldName="show_fine_grained_ratings_in_study_view"
              initialValue={userSettings.showFineGrainedRatingsInStudyView}
              displayTextForTrue="hide fine grained snooze options in study view"
              displayTextForFalse="show fine grained snooze options in study view"
            />
          </div>
        </li> */}
        <li>
          <div className="m-3">
            <button
              onClick={handleLogoutClick}
              style={{
                backgroundColor: "transparent",
                padding: "0px",
              }}
            >
              log out
            </button>
          </div>
        </li>
      </ul>
    </>
  );
}
