import React, { useRef, useEffect, useState, useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import ChildItem from "./ChildItem";
import GlobalContext from "../GlobalContext";

export default function ChildItems({
  item,
  navigatePageToItem,
  contextIsSidebar = false,
  loading,
  dragAndDrop,
}) {
  const { userSettings, setUserSettings } = useContext(GlobalContext);
  const onDeleteItem = (itemIdToDelete) => {
    setItems((prevItems) => {
      return prevItems.filter((item) => item.id !== itemIdToDelete);
    });
  };

  const [items, setItems] = useState(item?.childItems);

  useEffect(() => {
    setItems(item?.childItems);
  }, [item]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = [...items];
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    setItems(reorderedItems);
  };

  if (loading) return <div>loading...</div>;
  if (dragAndDrop) {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="childItemsDroppable">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <div className="m-2">
                <table>
                  <tbody>
                    {items?.map((childItem, index) => (
                      <ChildItem
                        key={childItem.id}
                        childItem={childItem}
                        index={index}
                        userSettings={userSettings}
                        onDeleteItem={onDeleteItem}
                        navigatePageToItem={navigatePageToItem}
                        contextIsSidebar={contextIsSidebar}
                        loading={loading}
                        draggable={dragAndDrop}
                      />
                    ))}
                    {provided.placeholder}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  } else {
    return (
      <div className="m-2">
        <table>
          <tbody>
            {items?.map((childItem, index) => (
              <ChildItem
                key={childItem.id}
                childItem={childItem}
                index={index}
                userSettings={userSettings}
                onDeleteItem={onDeleteItem}
                navigatePageToItem={navigatePageToItem}
                contextIsSidebar={contextIsSidebar}
                loading={loading}
                draggable={dragAndDrop}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
