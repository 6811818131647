import React, { useState, useRef, useEffect, useCallback } from "react";
import ItemView from "./ItemView";

const ItemModal = ({ onClose, item }) => {
  const handleOutsideClick = useCallback(
    (e) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    },
    [onClose]
  );

  const modalStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  };

  const contentStyle = {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
    width: "80%",
    maxWidth: "none",
    display: "flex",
    flexDirection: "column",
    height: "95%",
  };
  useEffect(() => {
    console.log(item);
  }, [item]);

  return (
    <div style={modalStyle} onClick={handleOutsideClick}>
      {item && (
        <div style={contentStyle}>
          <ItemView
            item={item}
            loading={false}
            error={false}
            navigatePageToItem={(newItemId) => {}}
            refetchItem={() => {}}
            publicView={false} // ??????????
            defaultToZenMode={false}
            purpose={"graph"}
            dragAndDrop={false}
          />
        </div>
      )}
    </div>
  );
};

export default ItemModal;
