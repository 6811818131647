import React from "react";

const ItemViewFooter = ({ pageNum = 0, setPageNum }) => {
  const generatePageNumbers = (current) => {
    const numbers = [];
    const actualCurrent = current === null ? 0 : current;
    const start = Math.max(0, actualCurrent - 2);
    const end = actualCurrent + 2;
    for (let i = start; i <= end; i++) {
      if (i >= 0) {
        numbers.push(i);
      }
    }
    return numbers;
  };

  return (
    <div className="flex gap-4 items-center justify-center p-4">
      {generatePageNumbers(pageNum).map((num) => (
        <button
          key={num}
          onClick={() => setPageNum(num)}
          className={`px-3 py-1 cursor-pointer ${
            num === (pageNum === null ? 0 : pageNum)
              ? "bg-blue-500 text-white"
              : "text-gray-600 hover:bg-gray-100"
          }`}
        >
          {num}
        </button>
      ))}
    </div>
  );
};

export default ItemViewFooter;
