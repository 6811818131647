import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";

const GENERATE_QUIZ_QUESTION = gql`
  mutation GenerateQuizQuestion($itemId: String!) {
    generateQuizQuestion(itemId: $itemId) {
      result
      newQuizQuestion
    }
  }
`;

export default function GenerateQuizQuestionButton({
  item,
  onGenerateSuccess,
}) {
  const [generateQuizQuestion, { loading, error }] = useMutation(
    GENERATE_QUIZ_QUESTION
  );
  const [isDisabled, setIsDisabled] = useState(false); // New state variable

  const handleGenerate = async () => {
    if (isDisabled) return; // Prevents function execution if button is already disabled

    try {
      const { data } = await generateQuizQuestion({
        variables: { itemId: item.id },
      });
      if (data.generateQuizQuestion.result === "success") {
        alert(
          "successfully made question:\n\n" +
            data.generateQuizQuestion.newQuizQuestion
        );
        setIsDisabled(true); // Disable button after successful request
        if (onGenerateSuccess) {
          onGenerateSuccess(item.id);
        }
      } else {
        alert(
          data.generateQuizQuestion.message ||
            "An error occurred while generating quiz question."
        );
      }
    } catch (error) {
      console.error("Failed to generate quiz question:", error);
      alert("Failed to generate quiz question: " + error);
    }
  };

  return (
    <>
      <button
        className="control-panel-button-rectangular"
        onClick={handleGenerate}
        disabled={loading || isDisabled} // Button is disabled when loading or after successful request
      >
        {isDisabled
          ? "success!"
          : loading
          ? "hang on"
          : "generate quiz question"}
      </button>
      {error && <p>Error in generating quiz question.</p>}
    </>
  );
}
