import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";

const GENERATE_SUMMARY = gql`
  mutation TriggerInformationLoginEmail  {
    triggerInformationLoginEmail {
      result
    }
  }
`;

export default function TriggerInformationLoginEmail() {
  const [generateSummary, { loading, error }] = useMutation(GENERATE_SUMMARY);
  const [isDisabled, setIsDisabled] = useState(false); // New state variable

  const handleGenerate = async () => {
    if (isDisabled) return; // Prevents function execution if button is already disabled

    try {
      const { data } = await generateSummary();
      if (data.triggerInformationLoginEmail.result === "success") {
        alert("successfully generated summary");
        setIsDisabled(true); // Disable button after successful request
      } else {
        alert(
          data.generateSummary.message ||
            "An error occurred while triggering login email."
        );
      }
    } catch (error) {
      console.error("Failed to do the thing:", error);
      alert("Failed to do the thing: " + error);
    }
  };

  return (
    <>
      <button
        className="control-panel-button-rectangular"
        onClick={handleGenerate}
        disabled={loading || isDisabled} // Button is disabled when loading or after successful request
      >
        {isDisabled ? "success!" : loading ? "hang on.." : "trigger information login email"}
      </button>
      {error && <p>Error in generating summary.</p>}
    </>
  );
}
