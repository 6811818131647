import react, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useMutation, useQuery, useLazyQuery, gql } from "@apollo/client";
import DraggableItem from "./bamboo-components/DraggableItem";

export default function TasksView() {
  const [tasks, setTasks] = useState([]);
  const [docs, setDocs] = useState([]);
  const [parentItem, setParentItem] = useState();
  const [autoExecute, setAutoExecute] = useState(false);
  const [showInputBox, setShowInputBox] = useState(false);
  const [newItemDescription, setNewItemDescription] = useState("");

  const GET_ITEMS = gql`
    query Items($parentItemId: String) {
      items(parentItemId: $parentItemId) {
        id
        type
        name
        public
        description
        additionalDescription
        include
        status
        parentItem {
          id
          description
        }
        childItems {
          id
        }
        assignableUsers {
          id
          username
        }
        assignedTo {
          id
          username
        }
      }
    }
  `;

  const [getItems, { loading, itemsData }] = useLazyQuery(GET_ITEMS, {
    fetchPolicy: "no-cache",
    variables: {
      parentItemId: parentItem?.id,
    },
    onCompleted(data) {
      setTasks(data.items.filter((item) => item["type"] === "TASK"));
      setDocs(data.items.filter((item) => item["type"] === "DOC"));
    },
    onError(err) {
      console.log("onerror", err);
      alert("Error occurred while fetching items.");
    },
  });

  useEffect(() => {
    getItems();
  }, []);

  const BUILD_TASK_LIST_MUTATION = gql`
    mutation BuildTaskListMutation($taskId: String!) {
      buildTaskListMutation(taskId: $taskId) {
        success
        userMessage
      }
    }
  `;

  const [buildTaskList] = useMutation(BUILD_TASK_LIST_MUTATION);

  // This is not currently used. It's here so that this component can choose to execute a specific task eventually.
  // const executeMutation = async () => {
  //   await executeTask({
  //     variables: {
  //       taskId: "bro",
  //     },
  //     update: (cache, { data }) => {
  //       console.log("updating the thing 1");
  //     },
  //   });
  // };

  // const toggleAutoExecute = () => {
  //   setAutoExecute(!autoExecute);
  // };

  const buildTaskListForItem = async (item) => {
    try {
      const { data } = await buildTaskList({
        variables: {
          taskId: item.id,
        },
      });
      getItems();
      setParentItem(item);
      setUserMessage(data.buildTaskListForItem.userMessage);
    } catch (error) {
      console.error("Error executing mutation:", error);
    }
  };

  const EXECUTE_TASK_VIA_BAMBOO_MUTATION = gql`
    mutation ExecuteTaskViaBamboo($taskId: String!) {
      executeTaskViaBamboo(taskId: $taskId) {
        success
        prompt
      }
    }
  `;
  const [executeTaskViaBambooMutation] = useMutation(
    EXECUTE_TASK_VIA_BAMBOO_MUTATION
  );
  const executeViaBamboo = async (task) => {
    try {
      const { data } = await executeTaskViaBambooMutation({
        variables: {
          taskId: task.id,
        },
      });
      getItems();
      setUserMessage(data.executeTaskViaBamboo.prompt);
    } catch (error) {
      console.error("Error executing mutation asdf4ew:", error);
    }
  };

  const EXECUTE_HOWEVER_MUTATION = gql`
    mutation ExecuteTaskHowever($itemId: String!) {
      executeTaskHowever(itemId: $itemId) {
        success
        userMessage
      }
    }
  `;
  const [executeHoweverMutation] = useMutation(
    EXECUTE_HOWEVER_MUTATION
  );
  const executeHowever = async (item) => {
    try {
      const { data } = await executeHoweverMutation({
        variables: {
          itemId: item.id,
        },
      });
      getItems();
      // setUserMessage("heyehalsdkjfhalskjdfhheyejhjh");
      // console.log("heyuhey", data)
      setUserMessage(data.executeTaskHowever.userMessage);
    } catch (error) {
      console.error("Error executing mutation asdf4ew:", error);
    }
  };

  const DELETE_ALL_TASKS_MUTATION = gql`
    mutation DeleteAllTasks($parentItemId: String!) {
      deleteAllTasks(parentItemId: $parentItemId) {
        success
      }
    }
  `;

  const [deleteAllTasks] = useMutation(DELETE_ALL_TASKS_MUTATION);

  const handleDeleteAllTasks = async () => {
    if (!parentItem || !parentItem.id) {
      console.error("Parent task ID not defined");
      return;
    }

    try {
      const { data } = await deleteAllTasks({
        variables: {
          parentItemId: parentItem ? parentItem.id : "null",
        },
      });

      if (data.deleteAllTasks.success) {
        getItems();
      } else {
        console.error("Error deleting tasks:", data.deleteAllTasks.message);
      }
    } catch (error) {
      console.error("Error executing deleteAllTasks mutation:", error);
    }
  };

  const CREATE_ITEM_MUTATION = gql`
    mutation CreateItem(
      $description: String!
      $parentItemId: String!
      $type: String!
    ) {
      createItem(
        description: $description
        parentItemId: $parentItemId
        type: $type
      ) {
        success
      }
    }
  `;

  const [createTask, { createTaskMutationLoading }] =
    useMutation(CREATE_ITEM_MUTATION);

  const handleAddTask = async () => {
    try {
      const { data } = await createTask({
        variables: {
          description: newItemDescription,
          parentItemId: parentItem ? parentItem.id : "null",
          type: "task",
        },
      });

      // Reset input field and hide it
      setNewItemDescription("");
      setShowInputBox(false);
      getItems();
    } catch (error) {
      console.error("Error executing createItem mutation:", error);
    }
  };

  const [createDoc, { createDocMutationLoading }] =
    useMutation(CREATE_ITEM_MUTATION);

  const handleAddDoc = async () => {
    try {
      const { data } = await createDoc({
        variables: {
          description: newItemDescription,
          parentItemId: parentItem ? parentItem.id : "null",
          type: "doc",
        },
      });

      // Reset input field and hide it
      setNewItemDescription("");
      setShowInputBox(false);
      getItems();
    } catch (error) {
      console.error("Error executing createItem mutation:", error);
    }
  };

  const handleSetParentClick = (item) => {
    setParentItem(item);
  };

  const handleSetTopLevelClick = () => {
    setParentItem(null);
  };

  const handleGoUpOneLevelClick = () => {
    setParentItem(parentItem ? parentItem.parentItem : null);
  };

  const DELETE_ITEM_MUTATION = gql`
    mutation DeleteItem($itemId: String!) {
      deleteItem(itemId: $itemId) {
        success
      }
    }
  `;

  const [deleteItem] = useMutation(DELETE_ITEM_MUTATION);

  const handleDeleteTask = async (item) => {
    try {
      const { data } = await deleteItem({
        variables: {
          itemId: item.id,
        },
      });

      if (data.deleteItem.success) {
        getItems();
      } else {
        console.error("Error deleting item:", data.deleteItem.message);
      }
    } catch (error) {
      console.error("Error executing deleteItem mutation:", error);
    }
  };

  const UPDATE_TASK_ORDER_MUTATION = gql`
    mutation UpdateTaskOrder($taskOrder: [String!]!) {
      updateTaskOrder(taskOrder: $taskOrder) {
        success
        message
      }
    }
  `;

  const [updateTaskOrder] = useMutation(UPDATE_TASK_ORDER_MUTATION);
  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const updatedTasks = Array.from(tasks);
    updatedTasks.splice(source.index, 1);
    updatedTasks.splice(destination.index, 0, tasks[source.index]);

    try {
      await updateTaskOrder({
        variables: {
          taskOrder: updatedTasks.map((task) => task.id),
        },
      });
    } catch (error) {
      console.error("Error updating task order:", error);
    }

    getItems();
  };

  const [userMessage, setUserMessage] = useState();

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {parentItem ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <h3
              style={{
                padding: "2rem",
              }}
            >
              parent item:
            </h3>
            <div
              style={{
                padding: "0.8rem",
                border: "1px solid #ccc",
                background: "#f6f6f6",
              }}
            >
              {parentItem.name} {parentItem.description}
            </div>
            {/* <h5 style={{ paddingLeft: "1rem", paddingTop: "1rem" }}>
          undraggable task box for parent task goes here
        </h5> */}
          </div>
        ) : (
          <div style={{ width: "auto" }} />
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "0.5rem",
          }}
        >
          {/* buttons */}
          <button
            onClick={handleGoUpOneLevelClick}
            style={{
              marginRight: "0.5rem",
              backgroundColor: "white",
              border: "1px solid lightgray",
              // borderRadius: "3px",
              padding: "0.25rem 0.5rem",
            }}
          >
            up one level
          </button>
          <button
            onClick={handleSetTopLevelClick}
            style={{
              marginRight: "0.5rem",
              backgroundColor: "white",
              border: "1px solid lightgray",
              // borderRadius: "3px",
              padding: "0.25rem 0.5rem",
            }}
          >
            top level
          </button>
          <button
            onClick={() => setShowInputBox(!showInputBox)}
            style={{
              marginRight: "0.5rem",
              backgroundColor: "white",
              border: "1px solid lightgray",
              // borderRadius: "3px",
              padding: "0.25rem 0.5rem",
            }}
          >
            +
          </button>
        </div>
      </div>

      {loading && (
        <div
          style={{
            textAlign: "center",
            marginTop: "1rem",
            fontSize: "1.2rem",
          }}
        >
          Loading tasks...
        </div>
      )}
      {showInputBox && (
        <div style={{ marginLeft: "20px" }}>
          <input
            value={newItemDescription}
            onChange={(e) => setNewItemDescription(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleAddTask();
              }
            }}
          />
          <button
            style={{
              marginLeft: "10px",
              border: "1px solid lightgray",
              background: "white",
            }}
            onClick={handleAddTask}
            disabled={loading}
          >
            {createTaskMutationLoading ? "adding item as task..." : "add task"}
          </button>
          <button
            style={{
              marginLeft: "10px",
              background: "white",
              border: "1px solid lightgray",
            }}
            onClick={handleAddDoc}
            disabled={loading}
          >
            {createDocMutationLoading ? "adding item as doc..." : "add doc"}
          </button>
        </div>
      )}
      <h3 style={{ paddingLeft: "1rem", paddingTop: "1rem" }}>tasks: </h3>
      <DragDropContext onDragEnd={onDragEnd}>
        <div style={{ borderRadius: "4px", padding: "1rem", margin: "1rem" }}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {tasks
                  // .filter((task) =>
                  //   parentitem
                  //     ? task.parentitem && task.parentitem.id === parentitem.id
                  //     : !task.parentitem
                  // )
                  .map((task, index) => (
                    <Draggable
                      key={task.id}
                      draggableId={task.id ? task.id.toString() : ""}
                      index={index}
                    >
                      {(provided) => (
                        <DraggableItem
                          key={task.id}
                          initialItem={task}
                          index={index}
                          provided={provided}
                          onBuildTaskListClick={buildTaskListForItem}
                          onExecuteViaBambooClick={executeViaBamboo}
                          onExecuteHoweverClick={executeHowever}
                          onSetParentClick={() => handleSetParentClick(task)}
                          onDelete={() => handleDeleteTask(task)}
                        />
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      <h3 style={{ paddingLeft: "1rem", paddingTop: "1rem" }}>docs: </h3>
      <DragDropContext onDragEnd={onDragEnd}>
        <div style={{ borderRadius: "4px", padding: "1rem", margin: "1rem" }}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {docs
                  // .filter((task) =>
                  //   parentItem
                  //     ? task.parentItem && task.parentItem.id === parentItem.id
                  //     : !task.parentItem
                  // )
                  .map((doc, index) => (
                    <Draggable
                      key={doc.id}
                      draggableId={doc.id ? doc.id.toString() : ""}
                      index={index}
                    >
                      {(provided) => (
                        <DraggableItem
                          key={doc.id}
                          initialItem={doc}
                          index={index}
                          provided={provided}
                          onBuildTaskListClick={buildTaskListForItem}
                          onExecuteViaBambooClick={executeViaBamboo}
                          onSetParentClick={() => handleSetParentClick(doc)}
                          onDelete={() => handleDeleteTask(doc)}
                        />
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      <div>
        {userMessage ? (
          <>
            <h3
              style={{
                padding: "2rem",
              }}
            >
              message from server:
            </h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                userSelect: "none",
                padding: "0.5rem 1rem",
                margin: "0 0 0.5rem 0",
                // background: "white",
                border: "1px solid #ccc",
                // background: generateBackgroundColor(index),
                // background: "#F4FCE3",
                background: "#f6f6f6",
              }}
            >
              <div
                style={{
                  padding: "0.8rem",
                  border: "1px solid #ccc",
                  background: "#f6f6f6",
                  whiteSpace: "pre-line",
                }}
              >
                {userMessage}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {/* <button onClick={() => getTasks()} className="m-2">
          refresh tasks 
        </button> */}
        {/* <button
          onClick={handleDeleteAllTasks}
          className="m-2"
          style={{
            marginRight: "0.5rem",
            backgroundColor: "white",
            border: "1px solid lightgray",
            borderRadius: "3px",
            padding: "0.25rem 0.5rem",
          }}
        >
          delete all tasks
        </button> */}
      </div>
    </>
  );
}
