import React, { useEffect, useRef, useState } from "react";
import ParentItemsView from "./ParentItemsView";
import ItemLinker from "./ItemLinker";
import { useQuery, useMutation, gql } from "@apollo/client";

export default function ParentItemsManager({
  item,
  itemModifierForDisplay,
  publicView,
  onItemClick,
}) {
  const [parentItems, setParentItems] = useState(item.parentItems);
  useEffect(() => {
    setParentItems(item.parentItems);
  }, [item]);
  const q = gql`
    mutation RemoveParentItem($itemId: String!, $parentItemId: String!) {
      removeParentItem(itemId: $itemId, parentItemId: $parentItemId) {
        item {
          id
          parentItems {
            id
            name
            isPublic
            importance
          }
        }
      }
    }
  `;

  const [mutateFunction, { data, loading, error }] = useMutation(q);
  useEffect(() => {
    if (data) {
      if (data.removeParentItem & data.removeParentItem.parentItems) {
        setParentItems(data.removeParentItem.parentItems);
      }
    }
  }, [data]);
  function handleRemoveTag(parentItem) {
    mutateFunction({
      variables: {
        itemId: item.id,
        parentItemId: parentItem.id,
      },
    });
  }
  useEffect(() => {
    if (typeof data !== "undefined") {
      setParentItems(data.removeParentItem.item.parentItems);
    }
  }, [data]);
  return (
    <div>
      <ParentItemsView
        parentItems={parentItems}
        removeParentFunction={handleRemoveTag}
        publicView={publicView}
        onItemClick={onItemClick}
      />
      <ItemLinker
        item={item}
        childOrParent={"parent"}
        submitCallback={setParentItems}
        itemModifierForDisplay={itemModifierForDisplay}
      />
    </div>
  );
}
