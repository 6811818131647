import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ItemRatingInner from "./ItemRatingInner";
import GlobalContext from "../GlobalContext";

export default function ParentItem({
  item,
  removeParentFunction,
  publicView,
  onItemClick,
}) {
  const { userSettings, setUserSettings } = useContext(GlobalContext);
  if (typeof item === "undefined" || item === null) {
    return <p>parent item is undefined</p>;
  }
  return (
    <tr>
      <td>
        <Link to={`/item?item_id=${item.id}`}>
          <div
            style={{ paddingRight: "4px", paddingLeft: "4px" }}
            className={`${item.type == "TAG" ? "tag-item" : ""}`}
            onClick={() => {
              if (typeof onItemClick === "function") {
                onItemClick(item.id);
              }
            }}
          >
            {item.name
              ? item.name
              : item.text
              ? item.text
              : "item_id: " + item.id}
          </div>
        </Link>
      </td>
      {!userSettings.hideRatingsInMostPlaces && (
        <td>
          <ItemRatingInner
            rating_type_for_display={""}
            rating_type={"importance"}
            item_id={item.id}
            existing_rating={item.importance}
          />
        </td>
      )}
      <td>
        {item.isPublic === "true" ? (
          <div className={`circle-button-inner is-public`}></div>
        ) : (
          <></>
        )}
      </td>
      <td>
        {!publicView ? (
          <button onClick={() => removeParentFunction(item)} className="m-1">
            x
          </button>
        ) : (
          <></>
        )}
      </td>
    </tr>
  );
}
