import React, { useState, useEffect } from "react";
import { ITEM_SLIM } from "../queries";
import ChildItems from "./ChildItems";
import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

function Sidebar({ isOpen, onClose }) {
  const [showLoading, setShowLoading] = useState(true);
  const navigate = useNavigate();

  const [
    getItem,
    { loading: itemDataLoading, error: itemDataError, data: itemData },
  ] = useLazyQuery(ITEM_SLIM, { fetchPolicy: "no-cache" });

  useEffect(() => {
    getItem();
  }, []);

  useEffect(() => {
    if (itemData?.item) {
      setShowLoading(false);
    }
  }, [itemData]);

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <ul
        style={{
          marginTop: "5px",
          listStyle: "none",
        }}
      >
        <li>
          <button onClick={onClose}>x</button>
        </li>
        <br />

        <li>
          <ChildItems
            contextIsSidebar={true}
            item={{ childItems: itemData?.item?.childItems }}
            navigatePageToItem={(itemId) => {
              navigate(`/item?item_id=${itemId}`);
              window.location.reload();
            }}
          />
          {showLoading && <div>loading...</div>}
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
