import React, { useState, useEffect, useRef } from "react";

const AudioPlayer = ({
  legAudioSequence,
  interruptAudioList, // Renamed to reflect it's now a list
  onInterruptComplete,
  shouldStop,
  onStopComplete,
}) => {
  const [sequenceIndex, setSequenceIndex] = useState(0);
  const [isPlayingSequence, setIsPlayingSequence] = useState(false);
  const [isPlayingInterrupt, setIsPlayingInterrupt] = useState(false);
  const [interruptIndex, setInterruptIndex] = useState(0);
  const audioRef = useRef(null);
  const sequencePausedAt = useRef(0);

  useEffect(() => {
    if (shouldStop) {
      stopAudio();
    }
  }, [shouldStop]);

  useEffect(() => {
    if (interruptAudioList && interruptAudioList.length > 0) {
      // We have interrupts, pause the sequence and play the interrupts
      if (isPlayingSequence) {
        sequencePausedAt.current = audioRef.current.currentTime;
        audioRef.current.pause();
      }
      setInterruptIndex(0);
      playInterruptAudio(0);
    }
  }, [interruptAudioList]);

  useEffect(() => {
    if (legAudioSequence && legAudioSequence.length > 0) {
      // Start playing the sequence from the beginning
      setSequenceIndex(0);
      sequencePausedAt.current = 0;
      playSequenceAudio(0);
    }
  }, [legAudioSequence]);

  const playSequenceAudio = (index) => {
    if (index < legAudioSequence.length) {
      audioRef.current.src = legAudioSequence[index];
      audioRef.current.currentTime = sequencePausedAt.current;
      audioRef.current
        .play()
        .then(() => {
          setIsPlayingSequence(true);
          setSequenceIndex(index);
        })
        .catch((error) => {
          console.error("Error playing sequence audio:", error);
          // Move to the next audio in sequence if there's an error
          sequencePausedAt.current = 0;
          playSequenceAudio(index + 1);
        });
    } else {
      // Sequence finished
      setIsPlayingSequence(false);
      sequencePausedAt.current = 0;
    }
  };

  const playInterruptAudio = (index) => {
    if (index < interruptAudioList.length) {
      audioRef.current.src = interruptAudioList[index];
      audioRef.current.currentTime = 0;
      audioRef.current
        .play()
        .then(() => {
          setIsPlayingInterrupt(true);
          setInterruptIndex(index);
        })
        .catch((error) => {
          console.error("Error playing interrupt audio:", error);
          // Move to the next interrupt audio if there's an error
          playInterruptAudio(index + 1);
        });
    } else {
      // Finished all interrupt audios
      setIsPlayingInterrupt(false);
      onInterruptComplete();
      // Resume sequence
      if (legAudioSequence && legAudioSequence.length > 0) {
        playSequenceAudio(sequenceIndex);
      }
    }
  };

  const handleAudioEnded = () => {
    if (isPlayingInterrupt) {
      // Move to next interrupt audio
      playInterruptAudio(interruptIndex + 1);
    } else if (isPlayingSequence) {
      // Move to next audio in sequence
      sequencePausedAt.current = 0;
      playSequenceAudio(sequenceIndex + 1);
    }
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlayingSequence(false);
      setIsPlayingInterrupt(false);
      setSequenceIndex(0);
      setInterruptIndex(0);
      sequencePausedAt.current = 0;
      if (onStopComplete) {
        onStopComplete();
      }
    }
  };

  return (
    <>
      <audio
        ref={audioRef}
        style={{ display: "none" }}
        onEnded={handleAudioEnded}
        onError={(e) => {
          console.error("Audio error:", e);
          handleAudioEnded();
        }}
      />
      {(isPlayingSequence || isPlayingInterrupt) && (
        <div
          style={{
            position: "absolute",
            top: 60,
            left: 10,
            zIndex: 10,
            display: "flex",
            gap: "10px",
          }}
        >
          <button
            onClick={() => {
              if (isPlayingSequence || isPlayingInterrupt) {
                audioRef.current.pause();
                if (isPlayingSequence) {
                  setIsPlayingSequence(false);
                } else {
                  setIsPlayingInterrupt(false);
                }
              } else {
                audioRef.current.play();
                if (isPlayingInterrupt) {
                  setIsPlayingInterrupt(true);
                } else {
                  setIsPlayingSequence(true);
                }
              }
            }}
            style={{
              padding: "10px 20px",
              backgroundColor: "white",
              border: "none",
              boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
              cursor: "pointer",
            }}
          >
            {isPlayingSequence || isPlayingInterrupt ? "pause" : "play"}
          </button>
          <button
            onClick={stopAudio}
            style={{
              padding: "10px 20px",
              backgroundColor: "white",
              border: "none",
              boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
              cursor: "pointer",
            }}
          >
            stop
          </button>
        </div>
      )}
    </>
  );
};

export default AudioPlayer;

