import React from "react";
import { SAVE_ITEM_RATING } from "../mutations";
import { useMutation } from "@apollo/client";

function SimpleSnoozePanel({ item, removeItemById }) {
  const [mutateFunction, { data, loading, error }] =
    useMutation(SAVE_ITEM_RATING);

  function handleSaveRating(rating) {
    mutateFunction({
      variables: {
        itemId: item.id,
        ratingType: "recall",
        rating: rating,
      },
    });
    removeItemById(item.id);
  }

  return (
    <div>
      <button
        style={{
          margin: "10px",
        }}
        onClick={() => handleSaveRating(0)}
      >
        {loading ? "hang on.." : "1"}
      </button>
      <button
        style={{
          margin: "5px",
        }}
        onClick={() => handleSaveRating(1)}
      >
        {loading ? "hang on.." : "3"}
      </button>
      <button
        style={{
          margin: "5px",
        }}
        onClick={() => handleSaveRating(2)}
      >
        {loading ? "hang on.." : "7"}
      </button>
      <button
        style={{
          margin: "5px",
        }}
        onClick={() => handleSaveRating(3)}
      >
        {loading ? "hang on.." : "30"}
      </button>
      <button
        style={{
          margin: "5px",
        }}
        onClick={() => handleSaveRating(4)}
      >
        {loading ? "hang on.." : "90"}
      </button>
      <button
        style={{
          margin: "5px",
        }}
        onClick={() => handleSaveRating(5)}
      >
        {loading ? "hang on.." : "∞"}
      </button>
    </div>
  );
}

export default SimpleSnoozePanel;
