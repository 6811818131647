import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";

export default function Upload({ gqlMutation, uploadType }) {
  const [fileContent, setFileContent] = useState("");
  const [uploadStatus, setUploadStatus] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [mutationResult, setMutationResult] = useState(null);

  const [uploadFile] = useMutation(gqlMutation, {
    onCompleted: (data) => {
      setIsUploading(false);
      setIsUploadComplete(true);
      setUploadStatus("k you're all set");
      if (data) {
        if (data.uploadReadwiseExportFile) {
          setMutationResult(data.uploadReadwiseExportFile.result);
        } else if (data.uploadMarkdownFile) {
          setMutationResult(data.uploadMarkdownFile.result);
        }
      }
    },
    onError: (error) => {
      setIsUploading(false);
      setUploadStatus(`Error retrieving file content: ${error.message}`);
    },
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      if (uploadType == "e-pub") {
        console.log("reading file as base 64")
        reader.onload = (e) => {
          // For binary files, consider using e.target.result as a base64-encoded string
          const base64Content = btoa(e.target.result);
          setFileContent(base64Content);
          setIsUploadComplete(false); // Reset upload completion status when a new file is picked
        };
        reader.readAsBinaryString(file);
      } else {
        console.log("reading file as text")
        reader.onload = (e) => {
          setFileContent(e.target.result);
          setIsUploadComplete(false); // Reset upload completion status when a new file is picked
        };
        reader.readAsText(file);
      }
    }
  };

  const handleUpload = async () => {
    if (!fileContent) {
      setUploadStatus("Please select a file first.");
      return;
    }

    setIsUploading(true);
    setUploadStatus("hang on..");

    await uploadFile({
      variables: {
        fileContent: fileContent,
      },
    });
  };

  return (
    <div style={{ margin: "20px" }}>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload} disabled={isUploading || isUploadComplete}>
        {isUploading ? "hang on.." : `upload ${uploadType} file`}
      </button>
      {uploadStatus && <div>{uploadStatus}</div>}
      {mutationResult && <div>result: {mutationResult}</div>}
    </div>
  );
}
